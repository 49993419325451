import { AppContext } from "@App";
import * as config from "@config";
import http from "@http";
import { useContext, useEffect, useState } from "react";

import Box from "../../Box";
import Modal from "./Modal";

export default function Index({ path, instancePath }: any) {
  const { setPageLoadingDisplay }: any = useContext(AppContext);
  const [databases, setDatabases]: any = useState(() => []);

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  useEffect(() => {
    setPageLoadingDisplay(true);
    http
      .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/manage/database`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
      })
      .then((response) => {
        // logger.debug({ values: response.data.values }, '[Databases]')
        setDatabases(() => response.data.rows);
      })
      .finally(() => {
        setPageLoadingDisplay(false);
      });
  }, []);

  // logger.debug('Databases')
  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 24px",
        }}
      >
        <div>
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create Database
          </button>
        </div>
      </div>
      <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath="/manage/database" />
      <br />
      <ul>
        {databases
          ? databases.map((value: any, index: number) => {
              const rows = [
                {
                  label: "Unique Db Id",
                  value: value.uniqueDbId,
                },
                {
                  label: "Database Name",
                  value: value.dbName,
                },

                {
                  label: "Active",
                  value: value.active,
                },
              ];

              return (
                <Box
                  path={path}
                  instancePath={instancePath}
                  key={index}
                  rows={rows}
                  apiBaseUrl={config.api.mongoBaseBaseUrl}
                  patchBasePath="/manage/database"
                  linkLabel="uniqueDbId"
                  linkValue={value.uniqueDbId}
                />
              );
            })
          : null}
      </ul>
    </div>
  );
}
