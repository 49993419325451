import App from "@App";
import { BrowserRouter as Router } from "react-router-dom";

// this parent file was added to wrap router around app
export default function AppParent() {
  return (
    <Router>
      <App />
    </Router>
  );
}
