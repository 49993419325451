import DataUpload from "./DataUpload";

function FileUploadWrapper() {
  return (
    <div>
      <div className="container mt-4">
        <h3 className="display-4 text-center mb-4">Data Upload</h3>
        <DataUpload />
      </div>
    </div>
  );
}

export default FileUploadWrapper;
