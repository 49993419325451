import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { DataStoreContext } from "../index";

function ExtraFilter({ filterCustomData, filterData, setFilterData }: any) {
  const { subUsers }: any = useContext(DataStoreContext);
  const { text, options } = filterCustomData;
  const [subUser, setSubUser]: any = useState(subUsers[0]);
  const [uploadType, setUploadType]: any = useState(options.uploadType[0]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      subUserId: subUser ? subUser.value : null,
      uploadType: uploadType ? uploadType.value : null,
    });
  }, [subUser, uploadType]);

  useEffect(() => setSubUser(subUsers[0]), [subUsers]);

  // logger.debug('ViewFiles.ExtraFilter', subUsers)
  return (
    <div className="row">
      <div className="col-md-7">
        <h6>{text.subUser}</h6>
        <Select className="form-control form-control-sm" value={subUser} options={subUsers} onChange={setSubUser} />
      </div>
      <div className="col-md-5">
        <h6>{text.uploadType}</h6>
        <Select className="form-control form-control-sm" value={uploadType} options={options.uploadType} onChange={setUploadType} />
      </div>
    </div>
  );
}

export default ExtraFilter;
