import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ClientAttacher({ clientId }: any) {
  const [loggerNames, setLoggerNames]: any = useState([]);
  const [loggers, setLoggers]: any = useState([]);
  const [attachedLoggers, setAttachedLoggers]: any = useState([]);
  logger.debug({ clientId, loggerNames }, "ClientAttacher");

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setLoggerNames(typeof value === "string" ? value.split(",") : value);
  };

  // 0) get
  const getAttachedLoggers = () => {
    http
      .makeGetRequestAuthorized(`${config.api.loggerBaseUrl}/manage/client/attach`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        clientId,
      })
      .then((response) => {
        setAttachedLoggers(response.data.rows);
      });
  };

  // 1) get
  const getAvailableLoggers = () => {
    http
      .makeGetRequestAuthorized(`${config.api.loggerBaseUrl}/manage/client/available`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        clientId,
      })
      .then((response) => {
        setLoggers(response.data.rows);
      });
  };

  // 2) post
  const attachAvailableLoggers = () => {
    http
      .makePostRequestAuthorized(`${config.api.loggerBaseUrl}/manage/client/attach?clientId=${clientId}`, {
        data: loggerNames.map((dn: any) => ({
          clientId,
          uniqueLoggerId: dn,
        })),
      })
      .then((response) => {
        if (response && response.message === "success") {
          // pass for now
        } else {
          error.messageHandling(response);
        }
      });
  };

  useEffect(() => {
    getAttachedLoggers();
    getAvailableLoggers();
  }, []);

  return (
    <div>
      <div>
        <h5>Attached Logger(s)</h5>
        <ul>
          {attachedLoggers.map((ad: any) => (
            <li>{`${ad.loggerName} (${ad.uniqueLoggerId})`}</li>
          ))}
        </ul>
      </div>
      <hr />
      <div>
        <h5>Select Logger(s)</h5>
        <div className="row">
          <div className="col-3">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Logger(s)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={loggerNames}
                onChange={handleChange}
                input={<OutlinedInput label="Logger(s)" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {loggers.map((lgr: any, idx: number) => (
                  <MenuItem key={idx} value={lgr.uniqueLoggerId}>
                    <Checkbox checked={loggerNames.indexOf(lgr.uniqueLoggerId) > -1} />
                    <ListItemText primary={`${lgr.loggerName} (${lgr.uniqueLoggerId})`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <button onClick={() => attachAvailableLoggers()} className="btn btn-warning">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAttacher;
