import LaunchIcon from "@mui/icons-material/Launch";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import createNotification from "@assets/nativelib/js/notification";
import http from "@http";
import logger from "@log";

import * as config from "@config";
import error from "@error";

import styles from "./Auth.module.scss";

function VerifyEmail() {
  //   const { authorized, setUser }: any = useContext(AppContext)
  const [isLoading1, setIsLoading1]: any = useState(false);
  const [isLoading2, setIsLoading2]: any = useState(false);

  const [email, setEmail]: any = useState("");
  const [otp, setOtp]: any = useState("");
  const [otpSent, setOtpSent]: any = useState(false);

  function validateForm1() {
    return email.length > 0 && !isLoading1;
  }

  function validateForm2() {
    return otp.length > 0 && !isLoading2;
  }

  const handleInputSubmit1 = (event: any) => {
    event.preventDefault();
    setIsLoading1(true);

    return http
      .makePostRequest(`${config.api.baseUrl}/auth/register/uniqueApId/verify-email/initiate/`, {
        "Email Id": email,
      })
      .then((response) => {
        logger.debug({ re: response }, "verify-email.handleInputSubmit1.then");
        if (response.message === "success") {
          createNotification({
            title: "Succesful",
            message: "Otp Sent Succesful",
            type: "success",
            container: "top-right",
          });
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        logger.debug({ err }, "");
        createNotification({
          title: "Error",
          message: "Unknown Error Occurred",
          type: "danger",
          container: "top-right",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading1(false);
        }, 400);
      });
  };

  const handleInputSubmit2 = (event: any) => {
    event.preventDefault();
    setIsLoading2(true);

    return http
      .makePostRequest(`${config.api.baseUrl}/auth/register/uniqueApId/verify-email/confirm/`, {
        "Email Id": email,
        otp,
      })
      .then((response: any) => {
        logger.debug({ re: response }, "verify-email.handleInputSubmit2.then");
        if (response.message === "success") {
          createNotification({
            title: "Succesful",
            message: "Email Verification Succesful",
            type: "success",
            container: "top-right",
          });
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err: any) => {
        logger.debug({ err }, "");
        createNotification({
          title: "Error",
          message: "Unknown Error Occurred",
          type: "danger",
          container: "top-right",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading2(false);
        }, 400);
      });
  };

  useEffect(() => {
    if (isLoading1) {
      setOtpSent(true);
    }
  }, [isLoading1]);

  return (
    <div className={styles.Auth}>
      <div className={styles.VerifyEmail}>
        <div style={{ display: "flex" }}>
          <h3>Verify Email</h3>
          <div>
            &nbsp;&nbsp;
            <Link to="/login/">
              <LaunchIcon />
              Login
            </Link>
          </div>
        </div>

        {/* 1) Send Otp */}
        <form onSubmit={handleInputSubmit1} className={otpSent ? styles.Disabled : ""}>
          <Form.Group>
            <Form.Label>
              Email <sup className={styles.compulsory__field}>*</sup>
            </Form.Label>
            <input className="form-control" name="email" autoFocus type="text" value={email} onChange={(event: any) => setEmail(event.target.value)} />
          </Form.Group>

          <Button size="lg" type="submit" variant="outline-primary" disabled={!validateForm1()}>
            <span>Send Otp</span>
            {isLoading1 && (
              <span className={styles.spinner}>
                <Spinner animation="grow" size="sm" />
              </span>
            )}
          </Button>
        </form>

        {/* 2) Submit Otp */}
        {otpSent && (
          <form onSubmit={handleInputSubmit2}>
            <hr />
            <Form.Group>
              <Form.Label>
                Otp <sup className={styles.compulsory__field}>*</sup>
              </Form.Label>
              <input
                className="form-control"
                name="otp"
                type="text"
                value={otp}
                onChange={(event: any) => {
                  if (/^([0-9]*)$/.test(event.target.value) && event.target.value.length <= 6) {
                    setOtp(event.target.value);
                  }
                }}
              />
            </Form.Group>

            <Button size="lg" type="submit" variant="primary" disabled={!validateForm2()}>
              <span>Submit Otp</span>
              {isLoading2 && (
                <span className={styles.spinner}>
                  <Spinner animation="grow" size="sm" />
                </span>
              )}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
