import { AppContext } from "@App";
import RedirectIfNotValid from "@helpers/RedirectIfNotValid";
import { AddPhotoAlternate as AddPhotoAlternateIcon, List as ListIcon, People as PeopleIcon, PersonAdd as PersonAddIcon } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";

import createNotification from "@assets/nativelib/js/notification";
import SwitchButton from "@custom/SwitchButton";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

import AddApiUser from "./AddApiUser";
import AddVideo from "./AddVideo";
import AddUser from "./AddUser";
import ViewApiUsers from "./ViewApiUsers";
import ViewVideos from "./ViewVideos";
import ViewUsers from "./ViewUsers";

export const VideoStoreContext = React.createContext({});

export function LeftNav({ path, styles }: any) {
  const [panic, setPanic]: any = useState(false);

  const onChange = (nowValue: any) => {
    http
      .makePatchRequestAuthorized(`${config.api.videoStoreBaseUrl}/manage/panic/`, {
        panic: nowValue ? "1" : "0",
      })
      .then((response) => {});
  };

  useEffect(() => {
    http.makeGetRequestAuthorized(`${config.api.videoStoreBaseUrl}/manage/panic/`).then((response) => {
      logger.debug({ response }, "panic.then");
      if (response && response.data && response.data.rows && response.data.rows.length) {
        logger.debug({ panic: response.data.rows[0].panic }, "panic.then.change");
        setPanic(response.data.rows[0].panic === "1");
      }
    });
  }, []);

  return (
    <>
      <div className={styles.Element}>
        <Link to={`${path}add/video`}>
          <span className={styles.Icon}>
            <AddPhotoAlternateIcon />
          </span>
          Add Video
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}view/videos`}>
          <span className={styles.Icon}>
            <ListIcon />
          </span>
          View Videos
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}add/user`}>
          <span className={styles.Icon}>
            <PersonAddIcon />
          </span>
          Add User
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}view/users`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          View Users
        </Link>
      </div>
      <hr />
      <div className={styles.Element}>
        <Link to={`${path}add/api-user`}>
          <span className={styles.Icon}>
            <PersonAddIcon />
          </span>
          Add Api User
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}view/api-users`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          View Api Users
        </Link>
      </div>
      <SwitchButton title="Panic" subtitle="freezes all video rights" onChange={onChange} checked={panic} />
    </>
  );
}

function Index({ path, setLeftNavPath }: any) {
  // eslint-disable-next-line
  useEffect(() => setLeftNavPath(path), [path]);
  const [subUsers, setSubUsers]: any = useState([]);

  const getInfo = () => {
    http
      .makeGetRequestAuthorized(`${config.api.videoStoreBaseUrl}/manage/sub-user/`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        inputSearch: "",
      })
      .then((response) => {
        if (response.message === "success") {
          const { rows } = response.data;
          if (response.data.count > 0) {
            setSubUsers(() =>
              rows.map((value: any) => ({
                ...value,
                value: value.subUserId,
                label: value.name,
              }))
            );
          } else {
            createNotification({
              title: "",
              message: "No Info Found",
              type: "info",
              container: "center",
            });
          }
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div style={{ margin: "8px 24px" }}>
      <VideoStoreContext.Provider
        value={{
          subUsers,
          setSubUsers,
        }}
      >
        <VideoStoreRoutes pathname={path} />
      </VideoStoreContext.Provider>
    </div>
  );
}

const validPrePathnames = ["/videostore/add/video", "/videostore/view/videos", "/videostore/add/user", "/videostore/view/users", "/videostore/add/api-user", "/videostore/view/api-users"];

export function VideoStoreRoutes({ pathname: prePath }: any) {
  const { pathname }: any = useContext(AppContext);

  return (
    <React.Fragment>
      <Routes>
        <Route path={`${prePath}add/video`} element={<AddVideo />} />
        <Route path={`${prePath}view/videos/*`} element={<ViewVideos />} />
        <Route path={`${prePath}add/user`} element={<AddUser />} />
        <Route path={`${prePath}view/users/*`} element={<ViewUsers />} />
        <Route path={`${prePath}add/api-user`} element={<AddApiUser />} />
        <Route path={`${prePath}view/api-users/*`} element={<ViewApiUsers />} />
      </Routes>
      <RedirectIfNotValid pathname={pathname} validPrePathnames={validPrePathnames} fallBackPathName={`${prePath}view/videos`} />
    </React.Fragment>
  );
}

export default Index;
