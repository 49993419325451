import * as config from "@config";
import http from "@http";
import logger from "@logger";
import { useEffect, useState } from "react";

import Box from "../../Box";
import Modal from "./Modal";

export default function Instance() {
  // const { user }: any = useContext(AppContext)
  const [instances, setInstances]: any = useState(() => []);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uniqueLoggerId = urlParams.get("uniqueLoggerId");
  logger.debug({ uniqueLoggerId }, "Logger.Instance");

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  const getInstances = () => {
    http
      .makeGetRequestAuthorized(`${config.api.loggerBaseUrl}/logger/`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        uniqueInstanceId: uniqueLoggerId,
      })
      .then((response) => {
        setInstances(response.data.rows);
      });
  };

  useEffect(() => {
    getInstances();
  }, []);

  // logger.debug('Instance')
  return (
    <div>
      <br />

      <div className="row" style={{ margin: "0" }}>
        <div className="col-1" />
        <div className="col-3">
          <button onClick={() => setModalShow(() => true)} className="btn btn-success" disabled>
            Create Entry
          </button>
        </div>
      </div>

      <hr />
      <br />

      <div
        style={{
          // display: 'none',
          // display: 'flex',
          // justifyContent: 'space-between',
          // margin: '10px 24px',
          padding: "0",
        }}
        // className="row"
      >
        <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath={`/instance/${uniqueLoggerId}`} />

        <br />
        <ul>
          {instances
            ? instances.map((value: any, index: number) => {
                const rows = [
                  {
                    label: "id",
                    value: value.id,
                  },
                  {
                    label: "Description",
                    value: value.description,
                  },
                  {
                    label: "Content",
                    value: value.content,
                    hidden: false,
                  },
                  {
                    label: "Active",
                    value: value.active,
                  },
                ];

                return <Box key={index} rows={rows} clientId={value.clientId} apiBaseUrl={config.api.loggerBaseUrl} patchBasePath={`/instance/${uniqueLoggerId}`} />;
              })
            : null}
        </ul>
      </div>
    </div>
  );
}
