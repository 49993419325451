import { AppContext } from "@App";
import * as config from "@config";
import http from "@http";
import { useContext, useEffect, useState } from "react";

import logger from "@log";
import Box from "../../Box";
import Modal from "./Modal";

export default function Index() {
  const { setPageLoadingDisplay }: any = useContext(AppContext);
  const [databases, setDatabases]: any = useState([]);

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  useEffect(() => {
    setPageLoadingDisplay(true);
    http
      .makeGetRequestAuthorized(`${config.api.nodeBaseBaseUrl}/manage/database`, {
        pageNumber: 100,
        pageSize: 1,
        orderType: "DESC",
      })
      .then((response) => {
        setDatabases(response.data.rows);
      })
      .finally(() => {
        setPageLoadingDisplay(false);
      });
  }, []);

  logger.debug(databases, "Nodebase.DataBase");
  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 24px",
        }}
      >
        <div>
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create Database
          </button>
        </div>
      </div>
      <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath="/manage/database" />
      <br />
      <ul>
        {databases.map((value: any, index: number) => {
          const rows = [
            {
              label: "Unique Db Id",
              value: value.uniqueDbId,
            },
            {
              label: "Database Name",
              value: value.dbName,
            },

            {
              label: "Active",
              value: value.active,
            },
          ];

          return <Box key={index} rows={rows} uniqueInstanceId={value.uniqueDbId} apiBaseUrl={config.api.nodeBaseBaseUrl} patchBasePath="/manage/database" reloadPath={"/manage/database/reload"} />;
        })}
      </ul>
    </div>
  );
}
