import { ExpandCircleDown as ExpandCircleDownIcon, ExpandLessRounded as ExpandLessRoundedIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";

const Item = ({ index, total, size, unit, progress }: any) => {
  return (
    <div>
      Idx: {index}/{total}
      &nbsp; Size: {size.toFixed(2)}
      {unit}
      &nbsp;&nbsp;&nbsp;&nbsp;
      {progress}%
    </div>
  );
};

const CUL = ({ chunks }: any) => {
  const [display, setDisplay]: any = useState(false);
  return (
    <div>
      <div>
        {!display ? (
          <>
            <IconButton onClick={() => setDisplay(true)}>
              <ExpandCircleDownIcon />
            </IconButton>
            Expand Progress
          </>
        ) : (
          <>
            <IconButton onClick={() => setDisplay(false)}>
              <ExpandLessRoundedIcon />
            </IconButton>
            Hide
          </>
        )}
      </div>

      {display && chunks.map((chunk: any, idx: number) => <Item key={idx} index={chunk.index} total={chunks.length} size={chunk.size} unit={chunk.unit} progress={chunk.progress} />)}
    </div>
  );
};

export default CUL;
