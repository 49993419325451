import queryString from "query-string";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import usePrevious_ from "./usePrevious";

/**
useEffect after first time render (2nd render onwards)
 */
export const useEffectAfterFirst = (callbackFunction: Function, dependencyList: any[]) => {
  const [isFirst, setIsFirst]: any = useState(true);
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      !!callbackFunction && callbackFunction();
    }

    return () => {};
  }, dependencyList);
};

export const usePrevious = usePrevious_;

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(
    () => ({
      // For convenience add push(), replace(), pathname at top level
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      location,
      history,
    }),
    [params, location, history]
  );
}

const useStateCallback = (initialState: any) => {
  const [state, setState]: any = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state: any, cb: any) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state updates
    if (cbRef.current) {
      // cbRef.current(state)
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};

const defaultObject = { useStateCallback, useEffectAfterFirst };
export default defaultObject;
