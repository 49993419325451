import { AppContext } from "@App";
import RedirectIfNotValid from "@helpers/RedirectIfNotValid";
import { People as PeopleIcon, Storage as StorageIcon } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";

import AuthPort from "./AuthPort";
import Client from "./Client";
import Instance from "./Instance";

export function LeftNav({ path, styles }: any) {
  return (
    <>
      <div className={styles.Element}>
        <Link to={`${path}authport`}>
          <span className={styles.Icon}>
            <StorageIcon />
          </span>
          AuthPort
        </Link>
      </div>
      {/* <div className={styles.Element}>
        <Link to={path + 'instance'}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          Instance
        </Link>
      </div> */}
      <div className={styles.Element}>
        <Link to={`${path}client`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          Client
        </Link>
      </div>
    </>
  );
}

const validPrePathnames = ["/authport/authport", "/authport/instance", "/authport/client"];

function Index({ path, setLeftNavPath }: any) {
  const { pathname }: any = useContext(AppContext);
  useEffect(() => setLeftNavPath(path), [path]);

  return (
    <React.Fragment>
      <Routes>
        <Route path={`${path}authport`} element={<AuthPort path={`${path}authport`} instancePath={`${path}instance`} />} />
        <Route path={`${path}instance`} element={<Instance />} />
        <Route path={`${path}client`} element={<Client />} />
      </Routes>
      <RedirectIfNotValid pathname={pathname} validPrePathnames={validPrePathnames} fallBackPathName={`${path}authport`} />
    </React.Fragment>
  );
}

export default Index;
