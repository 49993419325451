import cookie from "@cookie";
import axios from "axios";

const makeGetRequest = (url: string, params?: object) => {
  return axios.get(url, { params }).then((res) => {
    const responseBody = res.data;
    return responseBody;
  });
};

const makeGetRequestHeaders = (url: string, headers: object) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };
  const finalHeaders = { ...headers, ...defaultHeaders };
  return axios
    .get(url, {
      withCredentials: false,
      headers: finalHeaders,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    });
};

const makeGetRequestAuthorized = (url: string, params?: object) => {
  const headers = {
    Authorization: cookie.readCookie("jwtToken"),
    "Content-Type": "application/json",
  };
  return axios.get(url, { params, headers }).then((res) => {
    const responseBody = res.data;
    return responseBody;
  });
};

const makePostRequest = (url: string, params: object) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios
    .post(url, params, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    })
    .catch((err) => err);
};

const makePostRequestAuthorized = (url: string, params: object) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: cookie.readCookie("jwtToken"),
  };
  // logger.debug(headers, "headers:POST");
  return axios
    .post(url, params, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    })
    .catch((err) => err);
};

const makePatchRequestAuthorized = (url: string, params: object) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: cookie.readCookie("jwtToken"),
  };
  return axios
    .patch(url, params, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    });
};

const makeDeleteRequestAuthorized = (url: string, params?: object) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: cookie.readCookie("jwtToken"),
  };
  return axios
    .delete(url, {
      headers,
      data: params,
    })
    .then((res) => {
      const responseBody = res.data;
      return responseBody;
    });
};

const rObj = {
  makeGetRequest,
  makeGetRequestHeaders,
  makeGetRequestAuthorized,
  makePostRequest,
  makePostRequestAuthorized,
  makePatchRequestAuthorized,
  makeDeleteRequestAuthorized,
};
export default rObj;
