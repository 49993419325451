import cookie from "@cookie";
import Button from "react-bootstrap/Button";

import "./index.css";

function Account() {
  const onLogout = () => {
    cookie.eraseCookie("jwtToken");
    cookie.eraseCookie("user");
    window.location.pathname = "/login";
  };

  return (
    <div className="Account">
      <Button size="lg" type="submit" disabled={false} onClick={onLogout}>
        Logout
      </Button>
    </div>
  );
}

export default Account;
