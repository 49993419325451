// import logger from '../log'

const mapper = (pathnameP: string): string => {
  let pathname: string = pathnameP;
  const m: object | any = {
    "": "/favicon.ico",
    nodebase: "/nodebase/favicon.ico",
    mongobase: "/mongobase/favicon.ico",
    authport: "/authport/favicon.ico",
    logger: "/logger/favicon.ico",
    mailer: "/mailer/favicon.ico",
    filestore: "/filestore/favicon.ico",
    datastore: "/datastore/favicon.ico",
    videostore: "/videostore/favicon.ico",
  };

  let slashIndex: boolean | number = false;

  if (pathname && typeof pathname === typeof "string") {
    pathname = pathname.slice(1);
    slashIndex = pathname.indexOf("/");
  }

  if (slashIndex) {
    return m[pathname.substr(0, slashIndex)];
  }

  return m[""];
};

const favicon = (pathname: string): string => {
  const faviconUrl = mapper(pathname);
  //   logger.debug('sideEffects.favicon', faviconUrl)
  // document.getElementById("favicon").href = faviconUrl;
  return faviconUrl;
};
export default favicon;
