import { useState } from "react";
import RenderLeftNav from "../RenderLeftNav";
import Body from "./Body";
import { useStyles } from "./index.css";

export default function User() {
  const classes = useStyles({});
  const [leftNavPath, setLeftNavPath]: any = useState("");

  return (
    <div className={classes.User}>
      <RenderLeftNav sectionBasePath={leftNavPath} />
      <Body className={classes.Body} setLeftNavPath={setLeftNavPath} />
    </div>
  );
}
