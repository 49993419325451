const generateRandomNumericStream = (moreLen = 6, withDateTime = true) => {
  const arrAphaNumericStream = "123456789"; // 0 excluded
  let stream = "";
  for (let i = 0; i < moreLen; i += 1) {
    stream += arrAphaNumericStream[Math.floor(Math.random() * arrAphaNumericStream.length)];
  }
  if (withDateTime) {
    return stream + Date.now();
  }
  return stream;
};

const generateRandomUpperAlphaNumericStream = (moreLen = 6, withDateTime = true) => {
  const arrAphaNumericStream = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let stream = "";
  for (let i = 0; i < moreLen; i += 1) {
    stream += arrAphaNumericStream[Math.floor(Math.random() * arrAphaNumericStream.length)];
  }
  if (withDateTime) {
    return stream + Date.now();
  }
  return stream;
};

const generateRandomAlphaNumericStream = (moreLen = 6, withDateTime = true) => {
  const arrAphaNumericStream = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let stream = "";
  for (let i = 0; i < moreLen; i += 1) {
    stream += arrAphaNumericStream[Math.floor(Math.random() * arrAphaNumericStream.length)];
  }
  if (withDateTime) {
    return stream + Date.now();
  }
  return stream;
};

const randomTokenGenerator = (moreLen = 6, withDateTime = true) => {
  const arrAphaNumericStream = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789`~!@#$%^&*()-=_+\\|;:'\",<.>/?";
  let stream = "";
  for (let i = 0; i < moreLen; i += 1) {
    stream += arrAphaNumericStream[Math.floor(Math.random() * arrAphaNumericStream.length)];
  }
  if (withDateTime) {
    return stream + Date.now();
  }
  return stream;
};

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  if (sizes[i]) {
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }
  return `${parseFloat((bytes / k ** i).toFixed(dm))} Infinity`;
};

const includesInList = (stream: string, arrList: any[]) => {
  let found = false;
  arrList.forEach((al) => {
    if (stream.includes(al)) {
      found = true;
    }
  });

  return found;
};

const rObj = {
  generateRandomNumericStream,
  generateRandomUpperAlphaNumericStream,
  generateRandomAlphaNumericStream,
  randomTokenGenerator,
  formatBytes,
  includesInList,
};
export default rObj;
