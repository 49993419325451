import * as config from "@config";

const controlledLog = (...input: any): void => {
  if (config.mode === "development") {
    console.log(...input);
  } else {
    // pass
  }
};

class Log {
  // constructor() {}
  debug = (params: any, name: string | undefined = undefined): void => {
    if (params !== undefined && name !== undefined) {
      controlledLog("debug:ent123: ", name, params);
    } else if (params !== undefined) {
      controlledLog("debug:ent123: ", params);
    } else if (name !== undefined) {
      controlledLog("debug:ent123: ", name);
    } else {
      controlledLog("debug:ent123: ", "EMPTY");
    }
  };
  error = (params: any, name: string | undefined = undefined): void => {
    if (params !== undefined && name !== undefined) {
      controlledLog("error:ent123: ", name, params);
    } else if (params !== undefined) {
      controlledLog("error:ent123: ", params);
    } else if (name !== undefined) {
      controlledLog("error:ent123: ", name);
    } else {
      controlledLog("error:ent123: ", "EMPTY");
    }
  };
}

const logger = new Log();
export default logger;
