import { useContext, useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { FilterContext } from "./index";

function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill(1)
    .map((_, idx) => start + idx);
}

const Inner = ({ pageCount, pageNumber, setPageNumber }: any) => {
  return (
    <>
      {range(1, pageCount).map((item) => (
        <Pagination.Item key={item} active={item === pageNumber} onClick={() => setPageNumber(item)} activeLabel="">
          {item}
        </Pagination.Item>
      ))}
    </>
  );
};

const defaultData = {
  pageNumber: 1,
  pageCount: 4,
};

function Pagination_() {
  const { paginationData, setPaginationData }: any = useContext(FilterContext);

  const [pageNumber, setPageNumber]: any = useState(paginationData.pageNumber | defaultData.pageNumber);
  const pageCount = paginationData.pageCount | defaultData.pageCount;

  useEffect(() => {
    setPaginationData && setPaginationData({ ...paginationData, pageNumber, pageCount });
  }, [pageNumber]);

  return (
    <Pagination size="sm">
      {pageNumber && <Inner pageCount={pageCount} pageNumber={pageNumber} setPageNumber={setPageNumber} />}
      <div style={{ marginLeft: "8px" }}>Page Number: {pageNumber}</div>
    </Pagination>
  );
}

export default Pagination_;
