import { AppContext } from "@App";
import { useContext, useEffect, useState } from "react";

import * as config from "@config";
import http from "@http";

import Box from "../../Box";
import Modal from "./Modal";

export default function Index({ path, instancePath }: any) {
  const { setPageLoadingDisplay }: any = useContext(AppContext);
  const [mailers, setMailers]: any = useState(() => []);
  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  useEffect(() => {
    setPageLoadingDisplay(true);
    http
      .makeGetRequestAuthorized(`${config.api.mailerBaseUrl}/manage/mailer`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
      })
      .then((response) => {
        // logger.debug({ values: response.data.values }, '[mailer]')
        setMailers(() => response.data.rows);
      })
      .finally(() => {
        setPageLoadingDisplay(false);
      });
  }, []);

  // logger.debug('mailer')
  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 24px",
        }}
      >
        <div>
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create Mailer
          </button>
        </div>
      </div>
      <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath="/manage/mailer" />
      <br />
      <ul>
        {mailers
          ? mailers.map((value: any, index: number) => {
              const rows = [
                {
                  label: "Unique Mailer Id",
                  value: value.uniqueInstanceId,
                },
                {
                  label: "Mailer Name",
                  value: value.name,
                },

                {
                  label: "Host",
                  value: value.host,
                },
                {
                  label: "Port",
                  value: value.port,
                },
                {
                  label: "Secure",
                  value: value.secure,
                },

                {
                  label: "Email Id",
                  value: value.emailId,
                },
                {
                  label: "Password",
                  value: value.password,
                  hidden: true,
                },

                {
                  label: "Active",
                  value: value.active,
                },
              ];

              return (
                <Box
                  path={path}
                  instancePath={instancePath}
                  key={index}
                  rows={rows}
                  apiBaseUrl={config.api.mailerBaseUrl}
                  patchBasePath="/manage/mailer"
                  linkLabel="uniqueInstanceId"
                  linkValue={value.uniqueInstanceId}
                />
              );
            })
          : null}
      </ul>
    </div>
  );
}
