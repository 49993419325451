import { NOTIFICATION_CONTAINER, NOTIFICATION_TYPE, Store } from "react-notifications-component";

interface Params {
  title: string;
  message: string;
  type: NOTIFICATION_TYPE;
  container?: NOTIFICATION_CONTAINER;
  duration?: number;
}

//   type: "info",
//   type: "success",
//   type: "warning",
//   type: "danger",
//   container: "top-right",
//   container: "bottom-left",
//   container: "center",

const createNotification = (params: Params) => {
  Store.addNotification({
    title: params.title,
    message: params.message,
    type: params.type,
    insert: "top",
    container: params.container ? params.container : "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
      onScreen: true,
    },
  });
};
export default createNotification;
