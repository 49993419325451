import './index.css';

function Dashboard() {
  return (
    <div className="Dashboard">
      <div />

      <div>"Feeds Here..."</div>
    </div>
  );
}

export default Dashboard;
