import logger from "@log";
import string from "@string";
import { Navigate, Route, Routes } from "react-router-dom";

const validatePrePathname = (pathname: string, validPrePathnames: string[]) => string.includesInList(pathname, validPrePathnames);

function Index({ pathname, validPrePathnames, fallBackPathName }: any) {
  logger.debug({ pathname, validPrePathnames }, "[RedirectIfNotValid]");
  if (validatePrePathname(pathname, validPrePathnames)) {
    return <></>;
  }
  return (
    <Routes>
      <Route path="/*" element={<Navigate to={fallBackPathName} />} />
    </Routes>
  );
}

export default Index;
