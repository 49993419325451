import { AppContext } from "@App";
import Button from "@helpers/Button";
import hooks from "@hooks";
import { useContext, useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";
import string from "@string";

import "./FullCard.css";
import Rights from "./Rights";

function FullCard() {
  const { urlParams }: any = useContext(AppContext);
  const [loading, setLoading]: any = useState(() => false);
  const [loadingDownload, setLoadingDownload]: any = useState(false);
  const [video, setVideo]: any = useState({
    id: "",
    videoViewUrl: "",
    videoSize: "",
    videoName: "",
    originalVideoName: "",
    actives: "",
    softDeletedAt: "",
    hardDeletedAt: "",
  });
  const [videoRights, setVideoRights]: any = useState(() => []);

  const [originalVideoNameDashed, setOriginalVideoNameDashed] = hooks.useStateCallback(null);
  const [otk, setOtk]: any = hooks.useStateCallback(null);
  const [videoViewUrl, setVideoViewUrl]: any = useState(null);
  const [finalUrl, setFinalUrl]: any = useState(null);

  const uniqueVideoId = urlParams.get("uniqueVideoId");
  const subUserId = urlParams.get("subUserId");

  const getInfo = () => {
    http
      .makeGetRequestAuthorized(`${config.api.videoStoreBaseUrl}/manage/video/`, {
        uniqueVideoId,
        subUserId,
      })
      .then((response) => {
        const { videos, videosRights } = response.data;
        const video = videos[0];

        setVideo(video);
        setVideoRights(videosRights);
      })
      .catch((err: any) => error.messageHandling(err));
  };

  const createOtk = (_originalVideoNameDashed: string) => {
    return http
      .makeGetRequestAuthorized(`${config.api.videoStoreBaseUrl}/videostore/${uniqueVideoId}/${_originalVideoNameDashed}`, {})
      .then((response) => setOtk(response.data.otk))
      .catch((err: any) => error.messageHandling(err));
  };

  const createDownloadResource = () => {
    return http
      .makePostRequestAuthorized(`${config.api.videoStoreBaseUrl}/videostore/video/download-link/`, {
        uniqueVideoId,
        subUserId,
        videoNames: video.videoNames,
        originalVideoName: video.originalVideoName,
      })
      .then((response) => {
        setVideoViewUrl(response.data.videoViewUrls[0]); // only supports downloadType === full currently
        setOriginalVideoNameDashed(response.data.originalVideoNameDashed);
        createOtk(response.data.originalVideoNameDashed);
      })
      .catch((err: any) => error.messageHandling(err));
  };

  const createDownloadLink = () => {
    setLoadingDownload(true);
    createDownloadResource().finally(() => setLoadingDownload(false));
  };

  useEffect(() => {
    if (loading === false) {
      setLoading(() => true, getInfo());
    }
  }, [loading]);

  useEffect(() => {
    // for debug only
    logger.debug({ videoViewUrl, otk, originalVideoNameDashed }, "ViewVideos.FullCard");
  }, [videoViewUrl, otk, originalVideoNameDashed]);

  useEffect(() => {
    if (videoViewUrl && otk) {
      setFinalUrl(`${videoViewUrl}/?otk=${otk}`);
    }
  }, [videoViewUrl, otk]);

  return (
    <div>
      <br />
      <hr />
      <div className="row">
        <div className="col-7">
          <h2>Details</h2>
          <table className="table table-striped table-hover table-file">
            <tr>
              <th>URL</th>
              <td>
                {finalUrl ? (
                  <a className="btn btn-sm btn-outline-primary" rel={"noreferrer"} target={"_blank"} href={finalUrl} download={originalVideoNameDashed}>
                    Download
                  </a>
                ) : (
                  <Button size={"small"} variant={"contained"} color={"primary"} onClick={() => createDownloadLink()} loading={loadingDownload} text={"Create Download Link with OTK"} />
                )}
              </td>
            </tr>
            <tr>
              <th>Video Name</th>
              <td>{video.originalVideoName}</td>
            </tr>
            <tr>
              <th>Active</th>
              <td>
                {video &&
                video.actives.split(",").every((active: string) => {
                  return active.includes("-1");
                })
                  ? "1"
                  : "0"}
              </td>
            </tr>
            <tr>
              <th>Size</th>
              <td>{string.formatBytes(video.videoSize)}</td>
            </tr>
            <tr>
              <th>Permissions</th>
              <td>
                <div className="row">
                  <div className="col-2">
                    <Button size={"small"} variant={"outlined"} color={"warning"} onClick={() => () => {}} text={"Private"} />
                  </div>
                  <div className="col-2">
                    <Button size={"small"} variant={"outlined"} color={"success"} onClick={() => () => {}} text={"Public"} />
                  </div>
                  <div className="col-5"></div>
                  <div className="col-3">
                    <Button size={"small"} variant={"outlined"} color={"primary"} onClick={() => () => {}} text={"Submit"} />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <br />
      <hr />
      <Rights
        {...{
          video,
          videoRights,
          setVideoRights,
          getInfo,
          uniqueVideoId,
        }}
      />

      <br />
      <hr />
      <div>
        <h2>Preview</h2>
        <video id="videoPlayer" width="70%" controls>
          {uniqueVideoId && video && video.originalVideoName && <source src={`${config.api.videoStoreFileUrl}/${uniqueVideoId}/${video.originalVideoName.replaceAll(" ", "-")}`} type="video/mp4" />}
        </video>
      </div>
    </div>
  );
}

export default FullCard;
