import createNotification from "@assets/nativelib/js/notification";
import * as config from "@config";
import LoaderButton from "@helpers/Button";
import http from "@http";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import styles from "./Box.module.scss";
import Update from "./Update";

function CopyButton({ content }: any) {
  const defaultClassName = "btn btn-sm btn-secondary";
  const eventClassName = "btn btn-sm btn-outline-secondary";
  const [className, setClassName]: any = useState(() => defaultClassName);

  const defaultText = "copy";
  const eventText = "copied";
  const [text, setText]: any = useState(() => defaultText);

  return (
    <button
      className={`${className} ${styles.CopyButton}`}
      onClick={() => {
        navigator.clipboard.writeText(content);

        setClassName(() => eventClassName);
        setText(() => eventText);
        setTimeout(() => {
          setClassName(() => defaultClassName);
          setText(() => defaultText);
        }, 200);
      }}
    >
      {text}
    </button>
  );
}

function HiddenTd({ tdHidden, tdValue }: any) {
  const [hidden, setHidden]: any = useState(() => !!tdHidden);

  return (
    <div>
      {hidden ? (
        <>
          <span className={styles.HiddenText}>*******************</span>

          <button onClick={() => setHidden(() => !hidden)} className="btn btn-sm btn-primary">
            Show
          </button>
        </>
      ) : (
        <>
          {tdValue}

          <button onClick={() => setHidden(() => !hidden)} className="btn btn-sm btn-outline-primary">
            Hide
          </button>

          <CopyButton content={tdValue} />
        </>
      )}
    </div>
  );
}

export default function Box({ instancePath, rows, clientId, apiBaseUrl, patchBasePath, linkLabel, linkValue, reloadPath, uniqueInstanceId }: any) {
  let active;
  const [loaderReload, setLoaderReload] = useState(false);

  const onReload = () => {
    setLoaderReload(true);

    http
      .makePostRequestAuthorized(`${config.api.nodeBaseBaseUrl}${reloadPath}`, {
        uniqueInstanceId,
      })
      .then((response) => {
        if (response.success) {
          createNotification({
            title: "Successful",
            message: "Reload Successful",
            type: "warning",
            container: "top-center",
          });
        } else {
          createNotification({
            title: "Failure",
            message: "Reload Failed",
            type: "danger",
            container: "top-center",
          });
        }
      })
      .finally(() => {
        setLoaderReload(false);
      });
  };

  return (
    <div className={styles.Box}>
      <table>
        <tbody>
          {rows.map((value: any, index: number) => {
            if (value.label === "Active") {
              active = value.value;
            }

            return (
              <tr key={index}>
                <td>{value.label}</td>

                <td>
                  <HiddenTd tdHidden={value.hidden} tdValue={value.value} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      &nbsp; &nbsp;
      {reloadPath && <LoaderButton disabled={loaderReload} loading={loaderReload} color="warning" onClick={onReload} text={"Reload Instance"} />}
      &nbsp; &nbsp;
      <Update active={active} clientId={clientId} apiBaseUrl={apiBaseUrl} patchBasePath={patchBasePath} />
      &nbsp; &nbsp;
      {linkValue && (
        <Link to={`${instancePath}?${linkLabel}=${linkValue}`}>
          <Button className="btn btn-info">View</Button>
        </Link>
      )}
      <br />
    </div>
  );
}
