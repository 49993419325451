import PropTypes from "prop-types";

function Progress({ percentage, status }: { percentage: string; status: string }) {
  return (
    <div className="progress">
      {status === "success" ? (
        <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{ width: `${percentage}%` }}>
          {percentage}%
        </div>
      ) : (
        <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{ width: `${percentage}%` }}>
          {percentage}%
        </div>
      )}
    </div>
  );
}

Progress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default Progress;
