import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  User: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    zIndex: -1,
  },
  Body: {
    width: '100%',
    paddingTop: '2.8%',
    paddingBottom: '20vh',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 8px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(100,100,200,0.4)',
      outline: '1px solid slategrey',
    },
  },
});
