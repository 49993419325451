import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import createNotification from "@assets/nativelib/js/notification";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@log";

function Add() {
  const [data, setData]: any = useState({
    name: "",
    category: "",
    userType: "regular",
    type: "",
    description: "",
  });

  const handleInputChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setData({ ...data, [name]: value });
  };

  function validateForm() {
    return data.name.length > 0;
  }

  const handleInputSubmit = (event: any) => {
    event.preventDefault();
    return http
      .makePostRequestAuthorized(`${config.api.dataStoreBaseUrl}/manage/api-user/${data.userType}`, {
        Name: data.name,
        Category: data.category,
        Type: data.type,
        Description: data.description,
      })
      .then((response) => {
        logger.debug({ response }, "DataStore.AddUser");
        if (response.message === "success") {
          // resetInputs();
          createNotification({
            title: "Successful",
            message: "Api User Created",
            type: "success",
            container: "top-right",
          });
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  return (
    <div>
      <div className="Wrapper">
        <form onSubmit={handleInputSubmit}>
          <Form.Group>
            <Form.Label>
              Name <sup className="compulsory-field">*</sup>
            </Form.Label>
            <input className="form-control" name="name" autoFocus type="text" value={data.name} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              User Type <sup className="compulsory-field">*</sup>
            </Form.Label>
            <select name="userType" className="form-control" aria-label="Default select example" onChange={handleInputChange}>
              <option value="regular">Regular</option>
              <option value="admin">Admin</option>
              <option value="root">Root</option>
            </select>
          </Form.Group>

          <Form.Group>
            <Form.Label>Category</Form.Label>
            <input className="form-control" name="category" type="text" value={data.category} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <input className="form-control" name="type" type="text" value={data.type} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Short Description</Form.Label>
            <input className="form-control" name="description" type="text" value={data.description} onChange={handleInputChange} />
          </Form.Group>

          <Button size="lg" type="submit" disabled={!validateForm()}>
            Submit
          </Button>
        </form>
        <br />
      </div>
    </div>
  );
}

export default Add;
