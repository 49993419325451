function CheckBoxAll({ updateData, setUpdateData, displayData }: any) {
  const onClick = (key: string) => {
    setUpdateData(() => [
      ...displayData.map((value: any) => {
        const tempObject = value;
        tempObject.active = key;
        return tempObject;
      }),
    ]);
  };

  return (
    <div>
      {displayData && (
        <>
          <input type="checkbox" checked={displayData.every((v: any) => v.active === "1")} onClick={() => onClick("1")} />
          &nbsp;1 (All) &nbsp;&nbsp;
          <input type="checkbox" checked={displayData.every((v: any) => v.active === "0")} onClick={() => onClick("0")} />
          &nbsp;0 (All)
        </>
      )}
    </div>
  );
}

export default CheckBoxAll;
