import { AppContext } from "@App";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Auth from "../Auth";
import "./index.css";

const allowedUnAuthPathnames = ["/login", "/register", "/forgot-password"];

export default function Index() {
  const { pathname }: any = useContext(AppContext);

  if (allowedUnAuthPathnames.includes(pathname)) {
    return <Auth />;
  } else {
    return (
      <Routes>
        <Route path="/*" element={<Navigate to={"/login"} />} />
      </Routes>
    );
  }
}
