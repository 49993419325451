import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";

function Loader({ display = false }: any) {
  return (
    <div>
      {display && (
        <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
          <br />
          <LinearProgress color="primary" />
          <LinearProgress color="inherit" />
          <LinearProgress color="success" />
          <br />
        </Stack>
      )}
    </div>
  );
}

export default Loader;
