import Button from "@helpers/Button";
import hooks from "@hooks";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";
import string from "@string";

import "./FullCard.css";
import Rights from "./Rights";

function FullCard() {
  const [loading, setLoading]: any = useState(() => false);
  const [loadingDownload, setLoadingDownload]: any = useState(false);
  const [data, setData]: any = useState(() => ({
    id: "",
    fileViewUrl: "",
    fileSize: "",
    fileName: "",
    originalFileName: "",
    actives: "",
    softDeletedAt: "",
    hardDeletedAt: "",
  }));
  const [dataRights, setDataRights]: any = useState(() => []);

  const [originalDataNameDashed, setOriginalDataNameDashed] = hooks.useStateCallback(null);
  const [otk, setOtk]: any = hooks.useStateCallback(null);
  const [dataViewUrl, setDataViewUrl]: any = useState(null);
  const [finalUrl, setFinalUrl]: any = useState(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uniqueDataId = urlParams.get("uniqueDataId");
  const subUserId = urlParams.get("subUserId");

  const getInfo = () => {
    http
      .makeGetRequestAuthorized(`${config.api.dataStoreBaseUrl}/manage/data/`, {
        uniqueDataId,
        subUserId,
      })
      .then((response) => {
        const { datas, datasRights } = response.data;
        const data = datas[0];

        setData(data);
        setDataRights(datasRights);
      })
      .catch((err: any) => error.messageHandling(err));
  };

  const createOtk = (_originalDataNameDashed: string) => {
    return http
      .makeGetRequestAuthorized(`${config.api.dataStoreBaseUrl}/datastore/${uniqueDataId}/${_originalDataNameDashed}`, {})
      .then((response) => setOtk(response.data.otk))
      .catch((err: any) => error.messageHandling(err));
  };

  const createDownloadResource = () => {
    return http
      .makePostRequestAuthorized(`${config.api.dataStoreBaseUrl}/datastore/data/download-link/`, {
        uniqueDataId,
        subUserId,
        dataNames: data.dataNames,
        originalDataName: data.originalDataName,
      })
      .then((response) => {
        setDataViewUrl(response.data.dataViewUrls[0]); // only supports downloadType === full currently
        setOriginalDataNameDashed(response.data.originalDataNameDashed);
        createOtk(response.data.originalDataNameDashed);
      })
      .catch((err: any) => error.messageHandling(err));
  };

  const createDownloadLink = () => {
    setLoadingDownload(true);
    createDownloadResource().finally(() => setLoadingDownload(false));
  };

  useEffect(() => {
    if (loading === false) {
      setLoading(() => true, getInfo());
    }
  }, [loading]);

  useEffect(() => {
    // for debug only
    logger.debug({ dataViewUrl, otk, originalDataNameDashed }, "ViewDatas.FullCard");
  }, [dataViewUrl, otk, originalDataNameDashed]);

  useEffect(() => {
    if (dataViewUrl && otk) {
      setFinalUrl(`${dataViewUrl}/?otk=${otk}`);
    }
  }, [dataViewUrl, otk]);

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <h2>Details</h2>
          <br />
          <br />

          <table className="table table-striped table-hover table-file">
            <tr>
              <th>URL</th>
              <td>
                {finalUrl ? (
                  <a className="btn btn-sm btn-outline-primary" rel={"noreferrer"} target={"_blank"} href={finalUrl} download={originalDataNameDashed}>
                    Download
                  </a>
                ) : (
                  <Button size={"small"} variant={"contained"} color={"primary"} onClick={() => createDownloadLink()} loading={loadingDownload} text={"Create Download Link with OTK"} />
                )}
              </td>
            </tr>
            <tr>
              <th>Data Name</th>
              <td>{data.originalDataName}</td>
            </tr>
            <tr>
              <th>Active</th>
              <td>
                {data &&
                data.actives.split(",").every((active: string) => {
                  return active.includes("-1");
                })
                  ? "1"
                  : "0"}
              </td>
            </tr>
            <tr>
              <th>Size</th>
              <td>{string.formatBytes(data.dataSize)}</td>
            </tr>
          </table>
          {/* <br />
          <br /> */}

          {/* <button className="btn btn-outline-warning">Edit</button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button className="btn btn-outline-danger">Delete</button> */}
        </div>
      </div>

      <br />
      <br />
      <div className="head-cutter head-cutter-1px head-cutter-black" />
      <br />
      <Rights
        {...{
          data,
          dataRights,
          setDataRights,
          getInfo,
        }}
      />
    </div>
  );
}

export default FullCard;
