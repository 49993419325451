import { AppContext } from "@App";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

import styles from "./TopNav.module.scss";

function Index() {
  const { authorized }: any = useContext(AppContext);

  return (
    <div className={styles.TopNav}>
      {authorized ? (
        <React.Fragment>
          <div className={styles.LinksLeft}>
            <div className={styles.LogoWrapper}>
              <Link to="/">
                <img src="/logo.png" alt="CGK Cloud" className={styles.Logo} />
              </Link>
            </div>

            <div className={styles.Link}>
              <Link to="/nodebase">NodeBase</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/mongobase">MongoBase</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/voltbase">VoltBase</Link>
            </div>

            <div className={styles.Link}>
              <Link to="/authport">AuthPort</Link>
            </div>

            <div className={styles.Link}>
              <Link to="/logger">Logger</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/mailer">Mailer</Link>
            </div>

            <div className={styles.Link}>
              <Link to="/filestore">FileStore</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/datastore">DataStore</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/videostore">VideoStore</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/surveillance">Surveillance</Link>
            </div>
          </div>

          <div className={styles.LinksRight}>
            <Link to="/account">Account</Link>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles.LinksLeft}>
            <div className={styles.LogoWrapper}>
              <Link to="/">
                <img src="/logo.png" alt="CGK Cloud" className={styles.Logo} />
              </Link>
            </div>
          </div>
          <div className={styles.LinksRight}>
            <div className={styles.Link}>
              <Link to="/login">Login</Link>
            </div>
            <div className={styles.Link}>
              <Link to="/register">Register</Link>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Index;
