import cookie from './cookie';
import auth from '../constants/auth';

const getDefaultUserObject = () => {
  const userCookie = cookie.readCookie(auth.USER_OBJECT_NAME);
  const user = userCookie ? JSON.parse(userCookie) : {};
  return user;
};

const rObj = { getDefaultUserObject };
export default rObj;
