import { pink, red } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styles from "./SwitchButton.module.scss";
// import logger from '../../log'

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    // color: pink[600],
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    // backgroundColor: pink[600],
    backgroundColor: red[600],
  },
}));

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function Index({ checked, title, subtitle, onChange }: any) {
  const [value, setValue]: any = useState(checked);
  const onChangeLocal = (event: any) => {
    // logger.debug(!value) // currentValue
    !!onChange && onChange(!value);
    setValue((prev: any) => !prev);
  };

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  return (
    <div className={styles.SwitchButton}>
      <Form.Label className={styles.Label}>{title}</Form.Label>
      <sub className={styles.SubScript}>{subtitle}</sub>
      <br />
      <br />
      <br />
      <br />
      <GreenSwitch {...label} onChange={onChangeLocal} checked={value} />
    </div>
  );
}
