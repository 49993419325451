import { AppContext } from "@App";
import * as config from "@config";
import http from "@http";
import { useContext, useEffect, useState } from "react";

import Box from "../../Box";
import Modal from "./Modal";

export default function Index({ path, instancePath }: any) {
  const { setPageLoadingDisplay }: any = useContext(AppContext);
  const [authPorts, setAuthPorts]: any = useState(() => []);

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  useEffect(() => {
    setPageLoadingDisplay(true);
    http
      .makeGetRequestAuthorized(`${config.api.authPortBaseUrl}/manage/authport`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
      })
      .then((response) => {
        // logger.debug({ values: response.data.values }, '[AuthPorts]')
        setAuthPorts(() => response.data.rows || []);
      })
      .finally(() => {
        setPageLoadingDisplay(false);
      });
  }, []);

  // logger.debug('AuthPorts')
  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 24px",
        }}
      >
        <div>
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create AuthPort
          </button>
        </div>
      </div>
      <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath="/manage/authport" />
      <br />
      <ul>
        {authPorts.map((value: any, index: number) => {
          const rows = [
            {
              label: "Unique Ap Id",
              value: value.uniqueApId,
            },
            {
              label: "AuthPort Name",
              value: value.name,
            },

            {
              label: "Active",
              value: value.active,
            },
          ];

          return (
            <Box
              path={path}
              instancePath={instancePath}
              key={index}
              rows={rows}
              apiBaseUrl={config.api.authPortBaseUrl}
              patchBasePath="/manage/authport"
              linkLabel="uniqueApId"
              linkValue={value.uniqueApId}
            />
          );
        })}
      </ul>
    </div>
  );
}
