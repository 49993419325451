import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ClientAttacher({ clientId }: any) {
  const [mailerNames, setMailerNames]: any = useState([]);
  const [mailers, setMailers]: any = useState([]);
  const [attachedMailers, setAttachedMailers]: any = useState([]);
  logger.debug({ clientId, mailerNames }, "ClientAttacher");

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setMailerNames(typeof value === "string" ? value.split(",") : value);
  };

  // 0) get
  const getAttachedMailers = () => {
    http
      .makeGetRequestAuthorized(`${config.api.mailerBaseUrl}/manage/client/attach`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        clientId: clientId,
      })
      .then((response) => {
        setAttachedMailers(response.data.rows);
      });
  };

  // 1) get
  const getAvailableMailers = () => {
    http
      .makeGetRequestAuthorized(`${config.api.mailerBaseUrl}/manage/client/available`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        clientId: clientId,
      })
      .then((response) => {
        setMailers(response.data.rows);
      });
  };

  // 2) post
  const attachAvailableMailers = () => {
    http
      .makePostRequestAuthorized(`${config.api.mailerBaseUrl}/manage/client/attach/?clientId=${clientId}`, {
        data: mailerNames.map((dn: any) => ({
          clientId,
          uniqueMailerId: dn,
        })),
      })
      .then((response) => {
        if (response && response.message === "success") {
          // pass for now
        } else {
          error.messageHandling(response);
        }
      });
  };

  useEffect(() => {
    getAttachedMailers();
    getAvailableMailers();
  }, []);

  return (
    <div>
      <div>
        <h5>Attached Mailer(s)</h5>
        <ul>
          {attachedMailers.map((ad: any) => (
            <li>{`${ad.mailerName} (${ad.uniqueInstanceId})`}</li>
          ))}
        </ul>
      </div>
      <hr />
      <div>
        <h5>Select Mailer(s)</h5>
        <div className="row">
          <div className="col-3">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Mailer(s)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={mailerNames}
                onChange={handleChange}
                input={<OutlinedInput label="Mailer(s)" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {mailers.map((lgr: any, idx: number) => (
                  <MenuItem key={idx} value={lgr.uniqueMailerId}>
                    <Checkbox checked={mailerNames.indexOf(lgr.uniqueMailerId) > -1} />
                    <ListItemText primary={`${lgr.mailerName} (${lgr.uniqueInstanceId})`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <button onClick={() => attachAvailableMailers()} className="btn btn-warning">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAttacher;
