import { useEffect, useState } from "react";
import Select from "react-select";

function ExtraFilter({ filterCustomData, filterData, setFilterData }: any) {
  //   const { subUsers }: any = useContext(VideoStoreContext)
  const { text, options } = filterCustomData;
  //   const [subUser, setSubUser]: any = useState(subUsers[0])
  const [userType, setUserType]: any = useState(options.userType[0]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      userType: userType ? userType.value : null,
    });
  }, [userType]);

  //   useEffect(() => setSubUser(subUsers[0]), [subUsers])

  // logger.debug('ViewFiles.ExtraFilter', subUsers)
  return (
    <div className="row">
      <div className="col-md-5">
        <h6>{text.userType}</h6>
        <Select className="form-control form-control-sm" value={userType} options={options.userType} onChange={setUserType} />
      </div>
    </div>
  );
}

export default ExtraFilter;
