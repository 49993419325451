import { AppContext } from "@App";
import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Register from "./Register";
import VerifyEmail from "./VerifyEmail";

function Auth() {
  const { authorized }: any = useContext(AppContext);

  return (
    <Routes>
      {authorized ? (
        <React.Fragment>
          {/* <Route path="/login/" element={<Navigate to={"/"} />} />
          <Route path="/forgot-password/" element={<Navigate to={"/"} />} />
          <Route path="/register/" element={<Navigate to={"/"} />} />
          <Route path="/verify-email/" element={<Navigate to={"/"} />} /> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Route path="/login/" element={<Login />} />
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route path="/register/" element={<Register />} />
          <Route path="/verify-email/" element={<VerifyEmail />} />
        </React.Fragment>
      )}
    </Routes>
  );
}

export default Auth;
