import { AppContext } from "@App";
import RedirectIfNotValid from "@helpers/RedirectIfNotValid";
import { People as PeopleIcon, Storage as StorageIcon } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";

import Client from "./Client";
import Instance from "./Instance";
import Logger from "./Logger";

export function LeftNav({ path, styles }: any) {
  return (
    <>
      <div className={styles.Element}>
        <Link to={`${path}logger`}>
          <span className={styles.Icon}>
            <StorageIcon />
          </span>
          Logger
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}instance`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          Instance
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}client`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          Client
        </Link>
      </div>
    </>
  );
}

const validPrePathnames = ["/logger/logger", "/logger/instance", "/logger/client"];

function Index({ path, setLeftNavPath }: any) {
  const { pathname }: any = useContext(AppContext);
  useEffect(() => setLeftNavPath(path), [path]);

  return (
    <React.Fragment>
      <Routes>
        <Route path={`${path}logger`} element={<Logger path={`${path}logger`} instancePath={`${path}instance`} />} />
        <Route path={`${path}instance`} element={<Instance />} />
        <Route path={`${path}client`} element={<Client />} />
      </Routes>

      <RedirectIfNotValid pathname={pathname} validPrePathnames={validPrePathnames} fallBackPathName={`${path}logger`} />
    </React.Fragment>
  );
}

export default Index;
