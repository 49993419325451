import LaunchIcon from "@mui/icons-material/Launch";
import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import { AppContext } from "@App";
import createNotification from "@assets/nativelib/js/notification";
import auth from "@constants/auth";
import http from "@http";
import logger from "@log";

import * as config from "@config";
import cookie from "@cookie";
import error from "@error";

import styles from "./Auth.module.scss";

function Login() {
  const { authorized, setUser }: any = useContext(AppContext);
  const [isLoading, setIsLoading]: any = useState(false);

  const [data, setData]: any = useState({
    Username: "",
    Password: "",
  });

  const handleInputChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setData({ ...data, [name]: value });
  };

  function validateForm() {
    return data.Username.length > 0 && data.Password.length > 0;
  }

  const handleInputSubmit = (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    return http
      .makePostRequest(`${config.api.baseUrl}/auth/login/`, {
        UsernameOrEmail: data.Username,
        Password: data.Password,
      })
      .then((response) => {
        // logger.debug({ re: response }, 'login.then')
        if (response.message === "success") {
          // resetInputs();

          cookie.createCookie("jwtToken", response.data.jwtToken, 100);
          const user = {
            userId: response.data.userId,
            firstName: response.data.firstName,
            middleName: response.data.middleName,
            lastName: response.data.lastName,
            phoneCode: response.data.phoneCode,
            phone: response.data.phone,
          };
          cookie.createCookie(auth.USER_OBJECT_NAME, JSON.stringify(user), 100);
          setUser(() => user);

          createNotification({
            title: "Successful",
            message: "Login Successful",
            type: "success",
            container: "top-right",
          });

          setTimeout(() => {
            if (config.mode === "development") {
              window.location.pathname = "/authport";
            } else {
              window.location.pathname = "/nodebase"; // default
            }
          }, 1000);
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        logger.debug({ err }, "");
        createNotification({
          title: "Error",
          message: "Unknown Error Occurred",
          type: "danger",
          container: "top-right",
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 400);
      });
  };

  return (
    <div className={styles.Auth}>
      {/* <iframe width="720" height="480" src="http://localhost:3000/video/embed/852633854625757358763955"></iframe> */}
      <div className={styles.Login}>
        <div style={{ display: "flex" }}>
          <h3>Login</h3>
          <div>
            &nbsp;&nbsp;
            <Link to="/verify-email/">
              <LaunchIcon />
              Verify Email <sup>v1.3</sup>
            </Link>
          </div>
        </div>

        <form onSubmit={handleInputSubmit}>
          <Form.Group>
            <Form.Label>
              Username <sup className={styles.compulsory__field}>*</sup>
            </Form.Label>
            <input className="form-control" name="Username" autoFocus type="text" value={data.Username} onChange={handleInputChange} />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Password <sup className={styles.compulsory__field}>*</sup>
            </Form.Label>
            <input className="form-control" name="Password" type="password" value={data.Password} onChange={handleInputChange} />
          </Form.Group>

          <Button size="lg" type="submit" disabled={!validateForm() || isLoading || authorized}>
            <span>Login</span>
            {isLoading && (
              <span className={styles.spinner}>
                <Spinner animation="grow" size="sm" />
              </span>
            )}
          </Button>
        </form>

        <p className="text-center">
          Forgot Password ? &nbsp;
          <Link to="/forgot-password/">Click Here</Link>
        </p>

        <p className="text-center">
          Don't have an Account ? &nbsp;
          <Link to="/register/">Register</Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
