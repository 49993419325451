import * as config from "@config";
import cookie from "@cookie";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";

import { FileStoreContext } from "../index";
import "./FileUpload.css";
import Message from "./Message";
import Progress from "./Progress";

function FileUpload() {
  const { subUsers }: any = useContext(FileStoreContext);

  const [data, setData]: any = useState({ publicDisplay: "No" });
  const [open, setOpen]: any = useState(true); // notification message
  const [file, setFile]: any = useState("");
  const [filename, setFilename]: any = useState("Choose File");
  const [uploadedFile, setUploadedFile]: any = useState({});
  const [message, setMessage]: any = useState("");
  const [uploadPercentage, setUploadPercentage]: any = useState(0);
  const [status, setStatus]: any = useState(() => "success");
  const [subUserOptions, setSubUserOptions]: any = useState(
    subUsers.map((value: any, index: number) => ({
      value: value.subUserId,
      label: value.name,
    }))
  );
  const [subUser, setSubUser]: any = useState(subUserOptions[0]);

  const onChange = (e: any) => {
    if (e.target.files) {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
      }
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setUploadedFile({});

    const formData = new FormData();
    formData.append("file", file);
    formData.append("subUserId", subUser.value);
    formData.append("publicDisplay", data.publicDisplay);

    try {
      const res = await axios.post(`${config.api.fileStoreBaseUrl}/filestore/file/ui/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: cookie.readCookie("jwtToken"),
        },
        onUploadProgress: (progressEvent: any) => {
          setUploadPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
      });
      // Clear percentage
      setTimeout(() => setUploadPercentage(0), 10000);
      if (res.data.message === "success") {
        const { fileName, fileViewUrl } = res.data;
        setStatus("success");
        setUploadedFile({ fileName, fileViewUrl });
        setMessage("File Uploaded");
      } else {
        setStatus("danger");
        setMessage(res.data.errorMessage);
      }
    } catch (err: any) {
      // logger.debug({ err }, 'FileUpload.catch')
      setStatus("danger");
      setMessage(JSON.stringify(err));
      if (err && err.response && err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.errorMessage);
      }
      setUploadPercentage(0);
    }
    setOpen(true);
  };

  useEffect(() => {
    setSubUserOptions(
      subUsers.map((value: any, index: number) => ({
        value: value.subUserId,
        label: value.name,
      }))
    );
  }, [subUsers]);

  return (
    <div>
      <div className="row">
        <div className="col-6">
          {message ? <Message msg={message} status={status} open={open} setOpen={setOpen} /> : null}
          <form onSubmit={onSubmit}>
            <div className="custom-file mb-4">
              <input type="file" className="custom-file-input" id="customFile" onChange={onChange} />
              <label className="custom-file-label" htmlFor="customFile">
                {filename}
              </label>
            </div>

            <Progress percentage={uploadPercentage} status={status} />

            <input type="submit" value="Upload" className="btn btn-primary btn-block mt-4" />
          </form>

          <br />
          <br />
          <br />
          <h3>Select User</h3>
          <Select className="form-control form-control-lg" value={subUser} options={subUserOptions} defaultValue={subUserOptions[0]} onChange={setSubUser} />
        </div>
        <div className="col-6 public__display__wrapper">
          <h3>Public DISPLAY</h3>
          <br />
          <sub>Do you wan't this file to be accesed by general public ?</sub>
          <br />
          <sub>You can change the viewing rights in the future as well.</sub>
          <br />
          <br />

          <div
            className="form-check"
            onChange={(event: any) => {
              setData({ ...data, publicDisplay: event.target.value });
            }}
          >
            <input type="radio" className="form-check-input" id="radio1" name="optradio" value="Yes" />
            Yes
            <br />
            <input
              type="radio"
              className="form-check-input"
              id="radio2"
              name="optradio"
              value="No"
              // checked
            />
            No (Default)
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <br />
        {uploadedFile.fileViewUrl ? (
          <div className="file__upload__details">
            <h4>Upload Details</h4>
            <div className="row mt-5">
              <div className="col-md-12 m-auto">
                File Name: <b>{uploadedFile.fileName}</b>
                <br />
                <br />
                <a target="_blank" rel="noreferrer" href={uploadedFile.fileViewUrl} className="text-dark">
                  <b>{uploadedFile.fileViewUrl}</b>
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FileUpload;
