import { useEffectAfterFirst } from "@hooks";
import { useState } from "react";

function CheckBox({ zero: gZero, one: gOne, value, updateData, setUpdateData, displayData }: any) {
  // logger.debug({ id: value.id, active: value.active }, 'CheckBox:single')
  const [zero, setZero]: any = useState(gZero);
  const [one, setOne]: any = useState(gOne);
  // logger.debug({ id: value.id, zero, one, gZero, gOne }, 'CheckBox:single2')
  const valueMapper: any = {
    one,
    zero,
  };
  const functionMapper: any = {
    one: setOne,
    zero: setZero,
  };

  const onClick = (key: string) => {
    const f = functionMapper[key];
    const v = valueMapper[key];
    // logger.debug({ key, zero, one }, 'onClick')
    if (zero === false && one === false) {
      // should not happen
      f(true); // sets to true if it does
    } else if (zero === true && one === false) {
      if (key === "one") {
        f(!v);
        functionMapper.zero(false); // drips down to false
      }
    } else if (zero === false && one === true) {
      if (key === "zero") {
        f(!v);
        functionMapper.one(false); // drips down to false
      }
    } else {
      // should not happen
      f(false); // sets to false if it does
    }
  };

  useEffectAfterFirst(() => {
    let newUpdateData = updateData;
    const objectIndex = newUpdateData.findIndex((object: any) => object.id === value.id);
    if (objectIndex > -1) {
      const tempObject = newUpdateData[objectIndex];
      tempObject.active = one ? "1" : "0";
      newUpdateData[objectIndex] = tempObject;
    } else {
      const objectData = displayData.find((object: any) => object.id === value.id);
      objectData.active = one ? "1" : "0";
      newUpdateData = [...newUpdateData, objectData];
    }
    setUpdateData([...newUpdateData]);
  }, [zero, one]);

  return (
    <div className="form-check">
      {/* {(zero || one) && ( */}
      <span>
        <input type="radio" checked={gOne} className="form-check-input" onClick={() => onClick("one")} />1
      </span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span>
        <input type="radio" checked={gZero} className="form-check-input" onClick={() => onClick("zero")} />0
      </span>
      {/* )} */}
    </div>
  );
}

export default CheckBox;
