import { AppContext } from "@App";
import { vsBasePath } from "@AppEntry";
import * as config from "@config";
import error from "@error";
import http from "@http";
import React, { useContext, useEffect, useRef, useState } from "react";
// import video from "./cache/chunk.04.06.mp4";
import logger from "@log";
import Box from "@mui/material/Box";
import styles from "./Embed.module.scss";

import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { IconButton } from "@mui/material";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
// import VolumeDownIcon from "@mui/icons-material/VolumeDown";
// import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import SettingsIcon from "@mui/icons-material/Settings";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

// import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
// import ClosedCaptionDisabledIcon from '@mui/icons-material/ClosedCaptionDisabled';

function Index() {
  const { pathname }: any = useContext(AppContext);
  // [1/2] meta/basic info
  const uniqueVideoId = pathname.replace(vsBasePath, "");
  const [originalVideoName, setOriginalVideoName]: any = useState(null);
  const [minChunkNumber, setMinChunkNumber] = useState(0); // temp: hardcoded
  const [maxChunkNumber, setMaxChunkNumber] = useState(2); // temp: hardcoded
  const [chunkNumber, setChunkNumber]: any = useState(minChunkNumber);
  const [srcUrl, setSrcUrl]: any = useState(`${config.api.videoStoreFileUrl}/view/video/test?number=${chunkNumber}`);
  const [wrapperHeight, setWrapperHeight]: any = useState("100vh");
  const vidRef: any = useRef();
  logger.debug(`VV/Embed: ${chunkNumber}`);

  const Init = () => {
    http
      .makeGetRequest(`${config.api.videoStoreFileUrl}/${uniqueVideoId}/get-original-video-name`)
      .then((response) => setOriginalVideoName(response.originalVideoName))
      .catch((err: any) => error.messageHandling(err));
  };

  const handleVideoEnded = () => {
    logger.debug(`Video Ended: ${chunkNumber}`);
    setChunkNumber((_chunkNumber: number) => _chunkNumber + 1);
  };

  useEffect(() => {
    logger.debug(`srcUrl: ${srcUrl}`);
    playVideo();
    setCalculatedVolume();
  }, [srcUrl]);

  useEffect(() => {
    setSrcUrl(`${config.api.videoStoreFileUrl}/view/video/test?number=${chunkNumber}`);
  }, [chunkNumber]);

  useEffect(() => {
    if (uniqueVideoId) {
      Init();
    }
  }, [uniqueVideoId, vidRef]);

  // [2/2] controls
  const [play, setPlay]: any = useState(false); // true: play, false: pause
  const playVideo = () => setPlay(true);
  const pauseVideo = () => setPlay(false);
  useEffect(() => {
    if (play) vidRef.current.play();
    else vidRef.current.pause();
  }, [play]);

  const [volume, setVolume]: any = useState(true); // true: on, false: off
  const onVolume = () => setVolume(true);
  const offVolume = () => setVolume(false);
  const setCalculatedVolume = () => {
    if (volume) vidRef.current.muted = false;
    else vidRef.current.muted = true;
  };
  useEffect(() => {
    setCalculatedVolume();
  }, [volume]);

  // not-functional currently
  const [fullScreen, setFullScreen]: any = useState(true); // true: fullScreen, false: halfScreen
  const makeFullScreen = () => setFullScreen(true);
  const makeHalfScreen = () => setFullScreen(false);
  useEffect(() => {
    if (fullScreen) setWrapperHeight("100vh");
    else setWrapperHeight("60vh");
  }, [fullScreen]);

  /**
   * Debug Video Attributes
   * muted, controls
   * autoPlay
   */
  return (
    <React.Fragment>
      {true && (
        <div key={srcUrl} className={styles.Wrapper} style={{ height: wrapperHeight }}>
          <video ref={vidRef} onEnded={handleVideoEnded} className={styles.Video} autoPlay>
            <source src={srcUrl} type="video/mp4" />
          </video>
          <div className={styles.ControlBar}>
            <Box className={styles.Left}>
              <IconButton>{!play ? <PlayArrowIcon onClick={() => playVideo()} /> : <PauseIcon onClick={() => pauseVideo()} />}</IconButton>
              <IconButton>{!volume ? <VolumeOffIcon onClick={() => onVolume()} /> : <VolumeUpIcon onClick={() => offVolume()} />}</IconButton>
            </Box>

            <Box className={styles.Right}>
              <IconButton disabled={true}>
                <SettingsIcon />
              </IconButton>
              <IconButton disabled={true}>{!fullScreen ? <FullscreenIcon onClick={() => makeFullScreen()} /> : <FullscreenExitIcon onClick={() => makeHalfScreen()} />}</IconButton>
            </Box>
          </div>
        </div>
      )}
      {false && uniqueVideoId && originalVideoName ? (
        <video id="videoPlayer" width="100%" controls>
          <source src={`${config.api.videoStoreFileUrl}/${uniqueVideoId}/${originalVideoName.replaceAll(" ", "-")}`} type="video/mp4" />
        </video>
      ) : (
        <React.Fragment>{/* Error: <b>Unique-Video-Id</b> Required. */}</React.Fragment>
      )}
    </React.Fragment>
  );
}
export default Index;
