import { useEffect, useState } from "react";

import * as config from "@config";
import http from "@http";
import logger from "@logger";

import Box from "../../Box";
import Modal from "./Modal";

export default function Instance() {
  // const { user }: any = useContext(AppContext)
  const [instances, setInstances]: any = useState(() => []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uniqueInstanceId = urlParams.get("uniqueInstanceId");
  logger.debug({ uniqueInstanceId }, "Mailer.Instance");

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  const getInstances = () => {
    http
      .makeGetRequestAuthorized(`${config.api.mailerBaseUrl}/mailer/`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        uniqueInstanceId,
      })
      .then((response) => {
        logger.debug({ values: response.data.values }, "[Instance]");
        setInstances(() => response.data.rows);
      });
  };

  useEffect(() => {
    getInstances();
  }, []);

  // logger.debug('Instance')
  return (
    <div>
      <br />

      <div className="row" style={{ margin: "0" }}>
        <div className="col-1" />

        <div className="col-3">
          {/* <button
            onClick={() => setModalShow(() => true)}
            className="btn btn-success"
          >
            Create Entry
          </button> */}
        </div>
      </div>

      <hr />
      <br />

      <div
        style={{
          // display: 'none',
          // display: 'flex',
          // justifyContent: 'space-between',
          // margin: '10px 24px',
          padding: "0",
        }}
        // className="row"
      >
        <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath={`/instance/${uniqueInstanceId}`} />

        <br />
        <ul>
          {instances
            ? instances.map((value: any, index: number) => {
                const rows = [
                  {
                    label: "id",
                    value: value.id,
                  },
                  {
                    label: "Title",
                    value: value.title,
                  },
                  {
                    label: "Subject",
                    value: value.subject,
                  },
                  {
                    label: "To",
                    value: value.toList,
                    hidden: false,
                  },
                  {
                    label: "Body",
                    value: value.body,
                    hidden: false,
                  },
                  {
                    label: "BType",
                    value: value.bodyType,
                    hidden: false,
                  },
                  {
                    label: "CC",
                    value: value.ccList,
                    hidden: false,
                  },
                  {
                    label: "BCC",
                    value: value.bccList,
                    hidden: false,
                  },
                  {
                    label: "Feedback",
                    value: value.feedback,
                    hidden: true,
                  },
                  {
                    label: "Active",
                    value: value.active,
                  },
                ];

                return <Box key={index} rows={rows} clientId={value.clientId} apiBaseUrl={config.api.mailerBaseUrl} patchBasePath={`/instance/${uniqueInstanceId}`} />;
              })
            : null}
        </ul>
      </div>
    </div>
  );
}
