import createNotification from "@assets/nativelib/js/notification";
import logger from "@log";

const messageHandling = (response: any) => {
  logger.debug({ response }, "messageHandling.error");
  if (response.err) {
    const errList: Array<object> = response.err;

    for (let i = 0; i < errList.length; i += 1) {
      for (const [, value] of Object.entries(errList[i])) {
        // logger.debug(key, value);
        createNotification({
          title: value.errorTitle,
          message: value.errorMsg,
          type: value.errorType,
          container: "top-right",
        });
      }
    }
  } else if (response.errorMsg) {
    createNotification({
      title: response.errorTitle,
      message: response.errorMsg,
      type: response.errorType,
      container: "top-right",
    });
  } else if (response.errorMessage) {
    createNotification({
      title: response.errorTitle,
      message: response.errorMessage,
      type: response.errorType,
      container: "top-right",
    });
  } else if (response && response.response && response.response.data && response.response.data.errorMessage && response.response.data.errorTitle && response.response.data.errorType) {
    const { data } = response.response;
    createNotification({
      title: data.errorTitle,
      message: data.errorMessage,
      type: data.errorType,
      container: "top-right",
    });
  } else if (response && response.response && response.response.data && response.response.data.err && response.response.data.err.length) {
    const errList: Array<object> = response.response.data.err;

    for (let i = 0; i < errList.length; i += 1) {
      for (const [, value] of Object.entries(errList[i])) {
        logger.debug({ value }, "messageHandling.error.ei.2");
        createNotification({
          title: value.errorTitle,
          message: value.errorMsg,
          type: value.errorType,
          container: "top-right",
        });
      }
    }
  } else {
    createNotification({
      title: "Error",
      message: "Unknown Error Occurred",
      type: "danger",
      container: "top-right",
    });
  }
};

export default {
  messageHandling,
};
