import createNotification from "@assets/nativelib/js/notification";
import axios from "axios";
import { useEffect, useState } from "react";

import * as config from "@config";
import cookie from "@cookie";
import http from "@http";
import string from "@string";

import "./FullCard.css";
import Rights from "./Rights";

function FullCard() {
  // const { user }: any = useContext(AppContext)

  const [imgUrl, setImgUrl]: any = useState(null);
  const [loading, setLoading]: any = useState(() => false);
  const [data, setData]: any = useState(() => ({
    id: "",
    fileViewUrl: "",
    fileSize: "",
    fileName: "",
    originalFileName: "",
    active: "",
    softDeletedAt: "",
    hardDeletedAt: "",
  }));

  const [dataRights, setDataRights]: any = useState(() => []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fileName = urlParams.get("fileName");
  const subUserId = urlParams.get("subUserId");

  const getImg = (imageUrl: string) => {
    const headers = {
      Authorization: cookie.readCookie("jwtToken"),
    };
    let otk;
    http
      .makeGetRequestHeaders(`${config.api.fileStoreBaseUrl}/manage/file/?fileName=${fileName}&type=view`, headers)
      .then((response) => {
        // logger.debug({ response }, 'getImg.fetch1')
        otk = response.otk;
        // logger.debug({ imageUrl, otk }, 'getImg.fetch2')
        return axios.get(imageUrl, {
          responseType: "arraybuffer",
          params: { otk },
          // headers: { otk: '1234' },
        });
      })
      .then(
        (response) =>
          // logger.debug({ response }, 'getImg.fetch3')
          new Blob([response.data])
      )
      .then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        // logger.debug({ objectURL }, 'getImg.fetch4')
        setImgUrl(objectURL);
      })
      .catch((err) => {
        // logger.debug({ err }, 'getImg.catch1')
      });
  };

  const getInfo = () => {
    http
      .makeGetRequestAuthorized(`${config.api.fileStoreBaseUrl}/manage/file/?fileName=${fileName}&subUserId=${subUserId}`, {})
      .then((response) => {
        // logger.debug({ response }, '')
        if (response.message === "success") {
          const file = response.data.files[0];
          const { filesRights } = response.data;
          if (response.data.count > 0) {
            setData(() => file);
            setDataRights(() => filesRights);
            getImg(file.fileViewUrl); // blob image set
          } else {
            createNotification({
              title: "",
              message: "No Info Found",
              type: "success",
              container: "center",
            });
          }
        } else {
          createNotification({
            title: "Error",
            message: "Unknown Error Occurred",
            type: "danger",
            container: "center",
          });
        }
      })
      .catch((err) => {
        // logger.debug({ err }, "");
        createNotification({
          title: "Error",
          message: "Unknown Error Occurred",
          type: "danger",
          container: "center",
        });
      });
  };

  useEffect(() => {
    if (loading === false) {
      setLoading(() => true, getInfo());
    }
  }, [loading]);

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <h2>Details</h2>
          <br />
          <br />

          <table className="table table-striped table-hover table-file">
            <tr>
              <th>URL</th>
              <td>
                <a className="btn btn-sm btn-outline-primary" target="_blank" rel="noreferrer" href={data.fileViewUrl}>
                  View
                </a>
              </td>
            </tr>
            <tr>
              <th>File Name</th>
              <td>{data.originalFileName}</td>
            </tr>
            <tr>
              <th>Active</th>
              <td>{data.active}</td>
            </tr>
            <tr>
              <th>Size</th>
              <td>{string.formatBytes(data.fileSize)}</td>
            </tr>
          </table>
          {/* <br />
          <br /> */}

          {/* <button className="btn btn-outline-warning">Edit</button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button className="btn btn-outline-danger">Delete</button> */}
        </div>

        <div className="col-1" />
        <div className="col-4">
          <h3>Preview</h3>
          <br />
          <br />
          {imgUrl && <img draggable="false" src={imgUrl} className="file-preview-image" alt="preview" />}
        </div>
      </div>

      <br />
      <br />
      <div className="head-cutter head-cutter-1px head-cutter-black" />
      <br />
      <Rights
        {...{
          data,
          dataRights,
          setDataRights,
          getInfo,
        }}
      />
    </div>
  );
}

export default FullCard;
