import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Select from "react-select";

import * as config from "@config";
import cookie from "@cookie";
import logger from "@logger";
import string from "@string";

import ChunkListUpload from "./ChunkUploadList";
import "./DataUpload.css";
import Message from "./Message";
import Progress from "./Progress";

import { DataStoreContext } from "../index";

// max size per chunk:
const MB = 1024 * 1024;
// const MAX_CHUNK_SIZE = 50 * MB; // 50 MB
const MAX_CHUNK_SIZE = 25 * MB; // 25 MB
// const MAX_CHUNK_SIZE = 3 * MB; // 25 MB

function DataUpload() {
  const { subUsers }: any = useContext(DataStoreContext);
  const [chunks, setChunks]: any = useState([]);

  const [data, setData]: any = useState({ publicDisplay: "No" });
  const [open, setOpen]: any = useState(true); // notification message
  const [file, setFile]: any = useState("");
  const [filename, setFilename]: any = useState("Choose File");
  const [message, setMessage]: any = useState("");
  const [uploadPercentage, setUploadPercentage]: any = useState(0);
  const [status, setStatus]: any = useState(() => "success");
  const [subUserOptions, setSubUserOptions]: any = useState(
    subUsers.map((value: any, index: number) => ({
      value: value.subUserId,
      label: value.name,
    }))
  );
  const [subUser, setSubUser]: any = useState([]);

  const onChange = (e: any) => {
    if (e.target.files) {
      if (e.target.files[0]) {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
      }
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const uniqueDataId = string.generateRandomNumericStream(24, false);
    const url = `${config.api.dataStoreBaseUrl}/datastore/data/ui`;

    for (let iter = 0; iter < chunks.length; iter++) {
      const formData = new FormData();
      const fileMod = file.slice(MAX_CHUNK_SIZE * iter, MAX_CHUNK_SIZE * (iter + 1));

      formData.append("iter", (Number(iter) + 1).toString()); // iteration
      formData.append("total", chunks.length); // chunkCount
      formData.append("originalDataName", filename);
      formData.append("uniqueDataId", uniqueDataId);

      formData.append("file", fileMod);
      formData.append("subUserId", subUser.value);
      formData.append("publicDisplay", data.publicDisplay);

      await axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: cookie.readCookie("jwtToken"),
            "Access-Control-Allow-Origin": config.BASE_URL,
          },
          onUploadProgress: (progressEvent: any) => {
            const progress: number = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const cl = [...chunks];
            cl[iter].progress = progress;
            logger.debug({ iter, progress, cl }, "onUploadProgress");
            setChunks(cl);
          },
        })
        .then((res) => {
          logger.debug({ data: res.data }, "FileUpload.response");
          if (res.data.message === "success") {
            // const { fileName, fileViewUrl } = res.data;

            // cleaning up chunks-state (onSuccess)
            setTimeout(() => {
              if (iter === chunks.length - 1) {
                setMessage("File Uploaded");
                setStatus("success");
                setOpen(true);
                setChunks((_chunks: any[]) => _chunks.map((chunk) => ({ ...chunk, progress: 0 })));
              }
            }, 500);
          } else {
            setStatus("danger");
            setOpen(true);
            setMessage("Error!");
          }
        })
        .catch((err) => {
          // logger.debug({ err }, 'FileUpload.catch')
        });
    }
  };

  useEffect(() => {
    setUploadPercentage(
      (
        chunks.reduce((prev: any, curr: any) => {
          prev = prev + curr.progress;
          return prev;
        }, 0) / chunks.length
      ).toFixed(2)
    );
  }, [chunks]);

  useEffect(() => {
    if (subUser.length > 0) {
      setSubUserOptions(
        subUsers.map((value: any, index: number) => ({
          value: value.subUserId,
          label: value.name,
        }))
      );
      setSubUser(subUser[0]);
    }
  }, [JSON.stringify(subUsers)]);

  useEffect(() => {
    const fileSize = file.size;
    const sizeInMb = fileSize / MB;
    const chunkCount = Math.ceil(sizeInMb / (MAX_CHUNK_SIZE / MB));

    let endChunkSize = 0;
    let lChunks = [];

    if (chunkCount > 1) {
      endChunkSize = (fileSize % MAX_CHUNK_SIZE) / MB;
      // NOTE: do not round it off here, only round-off at the time of display

      for (let i = 1; i <= chunkCount; i = i + 1) {
        let sizeL = MAX_CHUNK_SIZE / MB;
        if (i === chunkCount) {
          sizeL = endChunkSize;
        }
        lChunks.push({
          index: i,
          size: sizeL,
          unit: "MB",
          progress: 0,
        });
      }
    } else {
      // size < 100MB
      lChunks.push({
        index: 1,
        size: sizeInMb,
        unit: "MB",
        progress: 0,
      });
    }

    setChunks(lChunks);
    logger.debug({ file, chunkCount, endChunkSize }, "FileUpload");
  }, [file]);

  return (
    <div>
      <div className="row">
        <div className="col-6">
          {message && <Message msg={message} status={status} open={open} setOpen={setOpen} />}
          <form onSubmit={onSubmit}>
            <div className="custom-file mb-4">
              <input type="file" className="custom-file-input" id="customFile" onChange={onChange} />
              <label className="custom-file-label" htmlFor="customFile">
                {filename}
              </label>
            </div>

            <Progress percentage={uploadPercentage} status={status} />

            {/* ToDo: in-progress var here */}
            <br />
            {uploadPercentage > 0 && chunks && <ChunkListUpload chunks={chunks} />}

            <input type="submit" value="Upload" className="btn btn-primary btn-block mt-4" />
          </form>

          <br />
          <br />
          <br />
          <h3>Select User</h3>
          <Select className="form-control form-control-lg" value={subUser} options={subUserOptions} defaultValue={subUserOptions[0]} onChange={setSubUser} />
        </div>
        <div className="col-6 public__display__wrapper">
          <h3>Public DISPLAY</h3>
          <br />
          <sub>Do you wan't this file to be accesed by general public ?</sub>
          <br />
          <sub>You can change the viewing rights in the future as well.</sub>
          <br />
          <br />

          <div
            className="form-check"
            onChange={(event: any) => {
              setData({ ...data, publicDisplay: event.target.value });
            }}
          >
            <input type="radio" className="form-check-input" id="radio1" name="optradio" value="Yes" />
            Yes
            <br />
            <input type="radio" className="form-check-input" id="radio2" name="optradio" value="No" />
            No (Default)
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default DataUpload;
