import { AppContext } from "@App";
import Card from "@helpers/Card";
import Filter from "@helpers/Filter";
import { useContext, useEffect, useState } from "react";

import * as config from "@config";
import string from "@string";

import ExtraFilter from "./ExtraFilter";
import FullCard from "./FullCard";

const filterCustomData = {
  options: {
    pageSize: [
      { value: 4, label: "04" },
      { value: 6, label: "06" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 250, label: "250" },
    ],
    uploadType: [
      { value: "ui", label: "UI" },
      { value: "api", label: "API" },
    ],
    orderType: [
      { value: "DESC", label: "Descending" },
      { value: "ASC", label: "Ascending" },
    ],
  },
  text: {
    subUser: "Sub User",
    inputSearch: "Search for File(s)",
    pageSize: "Page Size",
    uploadType: "Upload Type",
    orderType: "Display Order",
  },
};

const meta = {
  mainUrl: `${config.api.videoStoreBaseUrl}/manage/video/`,
};

const defaultVideo = {
  pageNumber: 1,
  pageSize: 4,
  pageCount: 7,
  uploadType: "UI",
  orderType: "DESC",
  inputSearch: "",
  subUserId: 4,
};

/**
 * Main Component
 */
function Index() {
  const { urlParams }: any = useContext(AppContext);
  const uniqueVideoId = urlParams.get("uniqueVideoId");
  const subUserId = urlParams.get("subUserId");

  const [paginationData, setPaginationData]: any = useState({
    pageNumber: defaultVideo.pageNumber,
    pageSize: defaultVideo.pageSize,
    pageCount: defaultVideo.pageCount,
    lock: false,
  });
  const [filterData, setFilterData]: any = useState({
    uploadType: defaultVideo.uploadType,
    orderType: defaultVideo.orderType,
    inputSearch: defaultVideo.inputSearch,
    subUserId: defaultVideo.subUserId,
    lock: false,
  });

  // fetched video
  const [data, setData]: any = useState({});

  const [searchParams, setSearchParams]: any = useState({
    pageSize: paginationData.pageSize,
    pageNumber: paginationData.pageNumber,
    uploadType: filterData.uploadType,
    orderType: filterData.orderType,
    inputSearch: filterData.inputSearch,
    subUserId: filterData.subUserId,
  });

  useEffect(() => {
    if (!paginationData.lock && !filterData.lock) {
      setSearchParams({
        pageSize: paginationData.pageSize,
        pageNumber: paginationData.pageNumber,
        uploadType: filterData.uploadType,
        orderType: filterData.orderType,
        inputSearch: filterData.inputSearch,
        subUserId: filterData.subUserId,
      });
    }
  }, [paginationData, filterData]);

  if (uniqueVideoId && subUserId) {
    return (
      <div className="List">
        <FullCard />
      </div>
    );
  }
  return (
    <div className="List">
      <Filter
        {...{
          filterCustomData,
          meta,
          searchParams,
          ExtraFilter,
          setData,
          paginationData,
          setPaginationData,
          filterData,
          setFilterData,
        }}
      />

      <div>
        <br />
        Total Space Used ({filterData.uploadType}
        ): <b>{string.formatBytes(data.totalSize | 0)}</b>
        <hr />
        <h5>List File(s) </h5>
        <span>
          {" "}
          Sub User:
          {filterData.subUserId}
        </span>
      </div>

      <br />
      {data &&
        data.rows &&
        data.rows.length > 0 &&
        data.rows.map((value: any, index: number) => (
          <Card
            key={index}
            to={`videos/?uniqueVideoId=${value.uniqueVideoId}&subUserId=${filterData.subUserId}`}
            data={[
              { label: "Number", value: value.countId },
              { label: "uniqueVideoId", value: value.uniqueVideoId },
              { label: "Video Name", value: value.originalVideoName },
              { label: "Uploaded At", value: value.createdAt },
            ]}
          />
        ))}
    </div>
  );
}

export default Index;
