const NODEBASE: string = "NodeBase";
const MONGOBASE: string = "MongoBase";
const AUTHPORT: string = "AuthPort";
const LOGGER: string = "Logger";
const MAILER: string = "Mailer";
const FILESTORE: string = "FileStore";
const DATASTORE: string = "DataStore";
const VIDEOSTORE: string = "VideoStore";

const mapper = (pathname: string): string => {
  const m: object | any = {
    "": "Cloud | CodeGK",
    "/": "Cloud | CodeGK",
    "/nodebase/database": `Database | ${NODEBASE}`,
    "/nodebase/client": `Client | ${NODEBASE}`,

    "/mongobase/database": `Database | ${MONGOBASE}`,
    "/mongobase/client": `Client | ${MONGOBASE}`,

    "/authport/authport": `Port | ${AUTHPORT}`,
    "/authport/client": `Client | ${AUTHPORT}`,

    "/logger/logger": `Logger | ${LOGGER}`,
    "/logger/client": `Client | ${LOGGER}`,

    "/mailer/mailer": `Mailer | ${MAILER}`,
    "/mailer/client": `Client | ${MAILER}`,

    "/filestore/add/file": `Add File | ${FILESTORE}`,
    "/filestore/view/files/": `View Files | ${FILESTORE}`,
    "/filestore/view/files": `View Files | ${FILESTORE}`,
    "/filestore/add/user": `Add User | ${FILESTORE}`,
    "/filestore/view/users/": `View Users | ${FILESTORE}`,
    "/filestore/view/users": `View Users | ${FILESTORE}`,
    "/filestore/add/api-user": `Add Api User | ${FILESTORE}`,
    "/filestore/view/api-users/": `View Api Users | ${FILESTORE}`,
    "/filestore/view/api-users": `View Api Users | ${FILESTORE}`,

    "/datastore/add/data": `Add Data | ${DATASTORE}`,
    "/datastore/view/datas/": `View Datas | ${DATASTORE}`,
    "/datastore/view/datas": `View Datas | ${DATASTORE}`,
    "/datastore/add/user": `Add User | ${DATASTORE}`,
    "/datastore/view/users/": `View Users | ${DATASTORE}`,
    "/datastore/view/users": `View Users | ${DATASTORE}`,
    "/datastore/add/api-user": `Add Api User | ${DATASTORE}`,
    "/datastore/view/api-users/": `View Api Users | ${DATASTORE}`,
    "/datastore/view/api-users": `View Api Users | ${DATASTORE}`,

    "/videostore/add/data": `Add Video | ${VIDEOSTORE}`,
    "/videostore/view/datas/": `View Videos | ${VIDEOSTORE}`,
    "/videostore/view/datas": `View Videos | ${VIDEOSTORE}`,
    "/videostore/add/user": `Add User | ${VIDEOSTORE}`,
    "/videostore/view/users/": `View Users | ${VIDEOSTORE}`,
    "/videostore/view/users": `View Users | ${VIDEOSTORE}`,
    "/videostore/add/api-user": `Add Api User | ${VIDEOSTORE}`,
    "/videostore/view/api-users/": `View Api Users | ${VIDEOSTORE}`,
    "/videostore/view/api-users": `View Api Users | ${VIDEOSTORE}`,
  };

  if (m[pathname]) {
    return m[pathname];
  }
  return m[""];
};

const title = (pathname: string): string => {
  const output = mapper(pathname);
  return output;
};
export default title;
