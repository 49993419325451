import { AppContext } from "@App";
import logger from "@log";
import { useContext } from "react";

import styles from "./RenderLeftNav.module.scss";

import { LeftNav as LeftNavAuthPort } from "../User/AuthPort";
import { LeftNav as LeftNavLogger } from "../User/Logger";
import { LeftNav as LeftNavMailer } from "../User/Mailer";
import { LeftNav as LeftNavMongoBase } from "../User/MongoBase";
import { LeftNav as LeftNavNodeBase } from "../User/NodeBase";

import { LeftNav as LeftNavDataStore } from "../User/DataStore";
import { LeftNav as LeftNavFileStore } from "../User/FileStore";
import { LeftNav as LeftNavVideoStore } from "../User/VideoStore";

function LeftNavSelector({ sectionBasePath }: any) {
  // logger.debug({ sectionBasePath }, 'LeftNavSelector')

  switch (sectionBasePath) {
    case "/nodebase/":
      return <LeftNavNodeBase styles={styles} path={sectionBasePath} />;
    case "/mongobase/":
      return <LeftNavMongoBase styles={styles} path={sectionBasePath} />;
    case "/authport/":
      return <LeftNavAuthPort styles={styles} path={sectionBasePath} />;
    case "/logger/":
      return <LeftNavLogger styles={styles} path={sectionBasePath} />;
    case "/mailer/":
      return <LeftNavMailer styles={styles} path={sectionBasePath} />;
    case "/filestore/":
      return <LeftNavFileStore styles={styles} path={sectionBasePath} />;
    case "/datastore/":
      return <LeftNavDataStore styles={styles} path={sectionBasePath} />;
    case "/videostore/":
      return <LeftNavVideoStore styles={styles} path={sectionBasePath} />;
    default:
      return <></>;
  }
}

function Index({ className, sectionBasePath }: any) {
  const { location }: any = useContext(AppContext);
  const name = sectionBasePath ? sectionBasePath.replaceAll("/", "") : location.host;
  logger.debug({ name }, "RenderLeftNav.name");

  return (
    <div className={`${className} ${styles.RenderLeftNav}`}>
      <center>
        <div className={styles.Wrapper}>{<LeftNavSelector {...{ sectionBasePath }} />}</div>
      </center>

      <div
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ position: "absolute", bottom: "30px" }}>
          <div>
            <center>
              <b>{name}</b>
            </center>
          </div>
          <div>
            <img draggable="false" width="186px" src={`/${name}/logo.png`} alt={name} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
