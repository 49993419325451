import PropTypes from "prop-types";
import "./Message.css";

function Message({ open, setOpen, msg, status }: { open: boolean; setOpen: Function; msg: string; status: string }) {
  return (
    <div>
      {open && (
        <>
          {status === "success" ? (
            <div className="alert alert-success alert-dismissible fade show">
              {msg}
              <button
                type="button"
                className="close"
                // data-dismiss="alert" // for closing x
                aria-label="Close"
                onClick={() => setOpen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : (
            <div>
              <div className="alert alert-danger alert-dismissible fade show">
                {msg}
                <button
                  type="button"
                  className="close"
                  // data-dismiss="alert" // for closing x
                  aria-label="Close"
                  onClick={() => setOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

Message.propTypes = {
  msg: PropTypes.string.isRequired,
};

export default Message;
