import favicon_ from './favicon';
import subTitle_ from './subTitle';
import title_ from './title';

export const favicon = favicon_;
export const subTitle = subTitle_;
export const title = title_;

export const defaultObject = { favicon, subTitle, title };
export default defaultObject;
