import { Button, CircularProgress, Typography } from "@mui/material";

const Index = ({ disabled = false, size, variant, color, onClick, loading, text }: any) => {
  return (
    <div>
      <Button disabled={disabled} size={size || "small"} variant={variant || "contained"} color={color || "primary"} onClick={onClick ? () => onClick() : () => {}}>
        {loading && <CircularProgress size={18} color={"inherit"} style={{ marginRight: "8px" }} />}
        <Typography>{text}</Typography>
      </Button>
    </div>
  );
};
export default Index;
