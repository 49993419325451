import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// Base Ui Components
import General from "@components/General";
import TopNav from "@components/TopNav";
import User from "@components/User";
import VsEmbed from "@components/User/VideoStore/ViewVideos/Embed";

//
import { AppContext } from "@App";

import authModule from "./auth.module";

export const vsBasePath = "/vs/embed";

export default function AppEntry() {
  const { pathname, authorized, setAuthorized }: any = useContext(AppContext);
  useEffect(() => {
    authModule.isAuthorized().then((authResponse) => {
      setAuthorized(authResponse);
    });
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/vs/embed/*" element={<VsEmbed />} />
      </Routes>

      {!pathname.includes(vsBasePath) && (
        <React.Fragment>
          <TopNav />
          {authorized === true ? <User /> : <General />}
        </React.Fragment>
      )}
    </div>
  );
}
