import { AppContext } from "@App";
import { useContext, useEffect, useState } from "react";

import * as config from "@config";
import http from "@http";

import Box from "../../Box";
import styles from "./Client.module.scss";
import ClientAttacher from "./ClientAttacher";
import Modal from "./Modal";

export default function Client() {
  const { setPageLoadingDisplay }: any = useContext(AppContext);
  const [clients, setClients]: any = useState(() => []);

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  useEffect(() => {
    setPageLoadingDisplay(true);
    http
      .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/manage/client?pageNumber=${1}&pageSize=${100}&orderType=${"DESC"}`, {})
      .then((response) => {
        setClients(() => response.data.rows);
      })
      .finally(() => {
        setPageLoadingDisplay(false);
      });
  }, []);

  // logger.debug('Client')
  return (
    <div>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 24px",
        }}
      >
        <div>
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create Client
          </button>
        </div>
      </div>

      <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath="/manage/client" />

      <br />
      <ul>
        {clients
          ? clients.map((value: any, index: number) => {
              const rows = [
                {
                  label: "Client Name",
                  value: value.clientName,
                },
                {
                  label: "Client Id",
                  value: value.clientId,
                },
                {
                  label: "Client Key",
                  value: value.clientKey,
                  hidden: true,
                },
                {
                  label: "Active",
                  value: value.active,
                },
              ];

              return (
                <div className={styles.BoxWrapper}>
                  <Box key={index} rows={rows} clientId={value.clientId} apiBaseUrl={config.api.mongoBaseBaseUrl} patchBasePath="/manage/client" />

                  <hr />
                  <ClientAttacher clientId={value.clientId} />
                </div>
              );
            })
          : null}
      </ul>
    </div>
  );
}
