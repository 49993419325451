import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ClientAttacher({ clientId }: any) {
  const [authPortNames, setAuthportNames]: any = useState([]);
  const [authPorts, setAuthports]: any = useState([]);
  const [attachedAuthports, setAttachedAuthports]: any = useState([]);
  logger.debug({ clientId, authPortNames }, "ClientAttacher");

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setAuthportNames(typeof value === "string" ? value.split(",") : value);
  };

  // 0) get
  const getAttachedAuthports = () => {
    http.makeGetRequestAuthorized(`${config.api.authPortBaseUrl}/manage/client/attach/?clientId=${clientId}&pageNumber=1&pageSize=100&orderType=DESC`, {}).then((response) => {
      setAttachedAuthports(response.data.rows);
    });
  };

  // 1) get
  const getAvailableAuthports = () => {
    http.makeGetRequestAuthorized(`${config.api.authPortBaseUrl}/manage/client/available/?clientId=${clientId}&pageNumber=1&pageSize=100&orderType=DESC`, {}).then((response) => {
      setAuthports(response.data.rows);
    });
  };

  // 2) post
  const attachAvailableAuthports = () => {
    http
      .makePostRequestAuthorized(`${config.api.authPortBaseUrl}/manage/client/attach`, {
        data: authPortNames.map((dn: any) => ({
          clientId,
          uniqueApId: dn,
        })),
      })
      .then((response) => {
        if (response && response.message === "success") {
          // pass for now
        } else {
          error.messageHandling(response);
        }
      });
  };

  useEffect(() => {
    getAttachedAuthports();
    getAvailableAuthports();
  }, []);

  return (
    <div>
      <div>
        <h5>Attached AuthPort(s)</h5>
        <ul>
          {attachedAuthports.map((ad: any) => (
            <li>{`${ad.name} (${ad.uniqueApId})`}</li>
          ))}
        </ul>
      </div>
      <hr />
      <div>
        <h5>Select AuthPort(s)</h5>
        <div className="row">
          <div className="col-3">
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">AuthPort(s)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={authPortNames}
                onChange={handleChange}
                input={<OutlinedInput label="AuthPort(s)" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {authPorts.map((lgr: any, idx: number) => (
                  <MenuItem key={idx} value={lgr.uniqueApId}>
                    <Checkbox checked={authPortNames.indexOf(lgr.uniqueApId) > -1} />
                    <ListItemText primary={`${lgr.name} (${lgr.uniqueApId})`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <button onClick={() => attachAvailableAuthports()} className="btn btn-warning">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAttacher;
