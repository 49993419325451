import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Modal from "react-modal";

import * as config from "@config";
import http from "@http";
import logger from "@logger";

const customStyles = {
  content: {
    top: "50%",
    left: "20%",
    minWidth: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-20%",
    // transform: "translate(-20%, -20%)",
  },
};

export default function ModalCustom({ modalShow, setModalShow, createBasePath }: any) {
  const [data, setData]: any = useState({
    client: "",
  });

  const refetchBox = () => {
    // currently reloading the page, later will fetch only the required chunk
    // window.location.reload()
  };

  const handleInputChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleClose = () => setModalShow(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    http
      .makePostRequestAuthorized(config.api.authPortBaseUrl + createBasePath, {
        clientName: data.client,
      })
      .then((response) => {
        // logger.debug({ data: response.data }, '[onSubmit]')
        handleClose();
        refetchBox();
      })
      .catch((err) => {
        logger.error({ err }, "[onSubmit][catch]");
      });
  };

  return (
    <Modal
      isOpen={modalShow}
      // onAfterOpen={afterOpenModal}
      onRequestClose={() => handleClose()}
      style={customStyles}
      contentLabel="Create Client"
    >
      <div className="flex justify-between">
        <h2>Create Client</h2>
        <button onClick={handleClose}>
          {/* close */}
          {/* <i class="fa fa-times" aria-hidden="true"></i> */}
          {/* <FontAwesomeIcon icon={["fab", "Close"]} /> */}
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <br />
      <form
        onSubmit={(event: any) => {
          handleSubmit(event);
        }}
      >
        <div>
          <label>Client Name</label>
          <input className="form-control" name="client" autoFocus type="text" value={data.client} onChange={handleInputChange} />
        </div>

        <br />
        <button type="submit" className="btn btn-sm btn-primary">
          Submit
        </button>
      </form>
    </Modal>
  );
}
