import { AppContext } from "@App";
import RedirectIfNotValid from "@helpers/RedirectIfNotValid";
import logger from "@log";
import { People as PeopleIcon, Storage as StorageIcon } from "@mui/icons-material";
import React, { useContext, useEffect } from "react";
import { Link, Route, Routes } from "react-router-dom";

import Client from "./Client";
import Database from "./Database";

export function LeftNav({ path, styles }: any) {
  return (
    <>
      <div className={styles.Element}>
        <Link to={`${path}database`}>
          <span className={styles.Icon}>
            <StorageIcon />
          </span>
          Database
        </Link>
      </div>
      <div className={styles.Element}>
        <Link to={`${path}client`}>
          <span className={styles.Icon}>
            <PeopleIcon />
          </span>
          Client
        </Link>
      </div>
    </>
  );
}

const validPrePathnames = ["/nodebase/database", "/nodebase/instance", "/nodebase/client"];

function Index({ path, setLeftNavPath }: any) {
  const { pathname }: any = useContext(AppContext);
  useEffect(() => setLeftNavPath(path), [path]);
  logger.debug({ path }, "Nodebase.Index");

  return (
    <React.Fragment>
      <Routes>
        <Route path={`${path}database`} element={<Database />} />
        <Route path={`${path}client`} element={<Client />} />
      </Routes>
      <RedirectIfNotValid pathname={pathname} validPrePathnames={validPrePathnames} fallBackPathName={`${path}database`} />
    </React.Fragment>
  );
}

export default Index;
