import http from "@http";
import logger from "@logger";
import string from "@string";

import styles from "./Box.module.scss";

export default function Update({ apiBaseUrl, patchBasePath, uniqueDbId, clientId, active }: any) {
  const refetchBox = () => {
    // currently reloading the page, later will fetch only the required chunk
    // window.location.reload()
  };

  const toggleActiveClient = () => {
    http
      .makePatchRequestAuthorized(`${apiBaseUrl + patchBasePath}/${clientId}`, {
        active: active === "1" ? "0" : "1",
      })
      .then((response) => {
        // logger.debug({ data: response.data }, '[toggleActiveClient]')
        refetchBox();
      })
      .catch((err) => {
        // logger.error({ err }, '[update][toggleActiveClient]')
      });
  };

  const toggleActiveDatabase = () => {
    http
      .makePatchRequestAuthorized(`${apiBaseUrl + patchBasePath}/${uniqueDbId}`, {
        active: active === "1" ? "0" : "1",
      })
      .then((response) => {
        // logger.debug({ data: response.data }, '[toggleActiveDatabase]')
        refetchBox();
      })
      .catch((err) => {
        logger.error({ err }, "[update][toggleActiveDatabase]");
      });
  };

  const changeClientKey = () => {
    http
      .makePatchRequestAuthorized(`${apiBaseUrl + patchBasePath}/${clientId}`, {
        clientKey: "1",
      })
      .then((response) => {
        // logger.debug({ data: response.data }, '[changeClientKey]')
        refetchBox();
      })
      .catch((err) => {
        logger.error({ err }, "[update][changeClientKey]");
      });
  };

  const deleteClient = () => {
    const otp = string.generateRandomNumericStream(4, false);
    const otpInput = prompt(`Are You Sure ?\nIf yes, then Please enter ${otp} in reverse Order\n`);

    const otpInputReversed = otpInput && otpInput.split("").reverse().join("");

    if (otpInputReversed === otp) {
      http
        .makeDeleteRequestAuthorized(`${apiBaseUrl + patchBasePath}/${clientId}`)
        .then((response) => {
          // logger.debug({ data: response.data }, '[deleteClient]')
          alert("Client Deleted");
          refetchBox();
        })
        .catch((err) => {
          logger.error({ err }, "[update][deleteClient]");
        });
    } else {
      alert("Invalid Entry");
    }
  };

  const deleteDatabase = () => {
    const otp = string.generateRandomNumericStream(4, false);
    const otpInput: string | null = prompt(`Are You Sure ?\nIf yes, then Please enter ${otp} in reverse Order\n`);
    let otpInputReversed: string;

    if (otpInput) {
      otpInputReversed = otpInput.split("").reverse().join("");
    } else {
      otpInputReversed = "0x0x0x";
    }

    if (otpInputReversed === otp) {
      http
        .makeDeleteRequestAuthorized(`${apiBaseUrl + patchBasePath}/${uniqueDbId}`)
        .then((response) => {
          // logger.debug({ data: response.data }, '[deleteDataBase]')
          alert("Database Deleted");
          refetchBox();
        })
        .catch((err) => {
          logger.error({ err }, "[update][deleteDataBase]");
        });
    } else {
      alert("Invalid Entry");
    }
  };

  const deleteEntry = () => {
    if (clientId) {
      deleteClient();
    }
    if (uniqueDbId) {
      deleteDatabase();
    }
  };

  const toggleActive = () => {
    if (clientId) {
      toggleActiveClient();
    }
    if (uniqueDbId) {
      toggleActiveDatabase();
    }
  };

  return (
    <div className={styles.Update}>
      <h6>Update Section</h6>
      <div>
        <button onClick={() => toggleActive()} className={`btn btn-sm btn-outline-danger ${styles.ToggleActive}`}>
          Toggle Active
        </button>

        {clientId ? (
          <button onClick={() => changeClientKey()} className={`btn btn-sm btn-outline-danger ${styles.ChangeClientKey}`}>
            Change Key
          </button>
        ) : null}
      </div>
      <div>
        <button onClick={() => deleteEntry()} className={`btn btn-sm btn-danger ${styles.Delete}`}>
          Delete
        </button>
      </div>
    </div>
  );
}
