import logger from "@log";

const mapper = (pathname: string): string => {
  const m: object | any = {
    "": "",
    "/": "Home",

    "/nodebase/database": "Database(s)",
    "/nodebase/client": "Client(s)",

    "/mongobase/database": "Database(s)",
    "/mongobase/client": "Client(s)",

    "/logger/logger": "Logger(s)",
    "/logger/client": "Client(s)",

    "/mailer/mailer": "Mailer(s)",
    "/mailer/client": "Client(s)",

    "/authport/authport": "AuthPort(s)",
    "/authport/client": "Client(s)",

    "/filestore/add/file": "Add File",
    "/filestore/view/files": "View Files",
    "/filestore/add/user": "Add User",
    "/filestore/view/users": "View Users",
    "/filestore/add/api-user": "Add Api User",
    "/filestore/view/api-users": "View Api Users",

    "/datastore/add/data": "Add Data",
    "/datastore/view/datas": "View Datas",
    "/datastore/add/user": "Add User",
    "/datastore/view/users": "View Users",
    "/datastore/add/api-user": "Add Api User",
    "/datastore/view/api-users": "View Api Users",

    "/videostore/add/video": "Add Video",
    "/videostore/view/videos": "View Videos",
    "/videostore/add/user": "Add User",
    "/videostore/view/users": "View Users",
    "/videostore/add/api-user": "Add Api User",
    "/videostore/view/api-users": "View Api Users",
  };

  if (m[pathname]) {
    return m[pathname];
  }
  return m[""];
};

const subTitle = (pathname: string): string => {
  logger.debug({ pathname }, "sideEffects.subTitle");
  const output = mapper(pathname);
  return output;
};
export default subTitle;
