import * as config from "@config";
import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import styles from "./Auth.module.scss";

function Signup() {
  const [userRegistration, setUserRegistration]: any = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    password: "",
  });

  const handleInput = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    setUserRegistration({ ...userRegistration, [name]: value });
  };

  function validateForm() {
    const newRecords = { ...userRegistration };
    const { emailId } = newRecords;
    const { password } = newRecords;
    return emailId.length > 0 && password.length > 0;
  }

  function handleSubmit(event: any) {
    event.preventDefault();

    const newRecords = { ...userRegistration };
    const { firstName } = newRecords;
    const { lastName } = newRecords;
    const { emailId } = newRecords;
    const { password } = newRecords;

    axios
      .post(`${config.api.baseUrl}/auth/register`, {
        firstName,
        lastName,
        emailId,
        password,
      })
      .then((res) => {
        // logger.debug(`statusCode: ${res.statusCode}`)
        // logger.debug(res)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <div className={styles.Auth}>
      <div className={styles.Register}>
        <h3>Register</h3>

        <form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <input className="form-control" name="firstName" autoFocus type="text" value={userRegistration.firstName} onChange={handleInput} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <input className="form-control" name="lastName" autoFocus type="text" value={userRegistration.lastName} onChange={handleInput} />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Email <sup className="compulsory-field">*</sup>
            </Form.Label>
            <input className="form-control" name="emailId" autoFocus type="email" value={userRegistration.emailId} onChange={handleInput} />
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Password <sup className="compulsory-field">*</sup>
            </Form.Label>
            <input className="form-control" name="password" type="password" value={userRegistration.password} onChange={handleInput} />
          </Form.Group>

          <Button size="lg" type="submit" className="btn btn-dark btn-lg btn-block" disabled={!validateForm()}>
            Register
          </Button>

          <p className="text-center">
            Already registered ? &nbsp;
            <Link to="/login/">LogIn</Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Signup;
