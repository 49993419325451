import { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import { AppContext } from "@App";
import createNotification from "@assets/nativelib/js/notification";
import * as config from "@config";
import Card from "@helpers/Card";
import Filter from "@helpers/Filter";

import ExtraFilter from "./ExtraFilter";
import Modify from "./Modify";

const filterCustomData = {
  options: {
    pageSize: [
      { value: 4, label: "04" },
      { value: 6, label: "06" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 250, label: "250" },
    ],
    uploadType: [
      { value: "ui", label: "UI" },
      { value: "api", label: "API" },
    ],
    orderType: [
      { value: "DESC", label: "Descending" },
      { value: "ASC", label: "Ascending" },
    ],
    userType: [
      { value: "regular", label: "Regular" },
      { value: "admin", label: "Admin" },
      { value: "root", label: "Root" },
    ],
  },
  text: {
    subUser: "Sub User",
    inputSearch: "Search for Api User(s)",
    pageSize: "Page Size",
    uploadType: "Upload Type",
    orderType: "Display Order",
    userType: "User Type",
  },
};

const metaMapper: any = {
  regular: {
    mainUrl: `${config.api.dataStoreBaseUrl}/manage/api-user/regular`,
  },
  admin: {
    mainUrl: `${config.api.dataStoreBaseUrl}/manage/api-user/admin`,
  },
  root: {
    mainUrl: `${config.api.dataStoreBaseUrl}/manage/api-user/root`,
  },
};

const defaultData: any = {
  pageNumber: 1,
  pageSize: 4,
  pageCount: 7,
  uploadType: "UI",
  orderType: "DESC",
  userType: "regular",
  inputSearch: "",
  subUserId: 4,
};

/**
 * Main Component
 */
function Index() {
  const { queryString }: any = useContext(AppContext);
  const urlParams = new URLSearchParams(queryString);
  const apiUserId = urlParams.get("apiUserId");

  const [paginationData, setPaginationData]: any = useState({
    pageNumber: defaultData.pageNumber,
    pageSize: defaultData.pageSize,
    pageCount: defaultData.pageCount,
    lock: false,
  });
  const [filterData, setFilterData]: any = useState({
    uploadType: defaultData.uploadType,
    orderType: defaultData.orderType,
    userType: defaultData.userType,
    inputSearch: defaultData.inputSearch,
    subUserId: defaultData.subUserId,
    lock: false,
  });

  // fetched data
  const [data, setData]: any = useState({});

  const [searchParams, setSearchParams]: any = useState({
    pageSize: paginationData.pageSize,
    pageNumber: paginationData.pageNumber,
    uploadType: filterData.uploadType,
    orderType: filterData.orderType,
    userType: filterData.userType,
    inputSearch: filterData.inputSearch,
    subUserId: filterData.subUserId,
  });

  useEffect(() => {
    if (!paginationData.lock && !filterData.lock) {
      setSearchParams({
        pageSize: paginationData.pageSize,
        pageNumber: paginationData.pageNumber,
        uploadType: filterData.uploadType,
        orderType: filterData.orderType,
        userType: filterData.userType,
        inputSearch: filterData.inputSearch,
        subUserId: filterData.subUserId,
      });
    }
  }, [paginationData, filterData]);

  if (apiUserId) {
    return <Modify apiUserId={apiUserId} userType={filterData.userType} />;
  }
  return (
    <div>
      <Filter
        {...{
          filterCustomData,
          meta: metaMapper[searchParams.userType],
          searchParams,
          ExtraFilter,
          setData,
          paginationData,
          setPaginationData,
          filterData,
          setFilterData,
        }}
      />
      <div>
        <hr />
        <h5>List Api User(s) </h5>
      </div>
      <br />
      {data &&
        data.rows &&
        data.rows.length > 0 &&
        data.rows.map((value: any, index: number) => (
          <Card
            key={value.id}
            to={`api-users/?apiUserId=${value.apiUserId}`}
            data={[
              { label: "Number", value: value.id },
              { label: "User Name", value: value.name },
              { label: "Category", value: value.category },
              { label: "Description", value: value.description },
              {
                label: "X Auth Id",
                value: (
                  <Button
                    variant="dark"
                    className="copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(value.xAuthId);
                      createNotification({
                        title: "",
                        message: "Copied!",
                        type: "info",
                        duration: 220,
                      });
                    }}
                  >
                    COPY
                  </Button>
                ),
              },
              {
                label: "X Auth Bearer",
                value: (
                  <Button
                    variant="dark"
                    className="copy-button"
                    onClick={() => {
                      navigator.clipboard.writeText(value.authToken);
                      createNotification({
                        title: "",
                        message: "Copied!",
                        type: "info",
                        duration: 220,
                      });
                    }}
                  >
                    COPY
                  </Button>
                ),
              },
            ]}
          />
        ))}
    </div>
  );
}

export default Index;
