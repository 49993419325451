import { useEffect, useState } from "react";
import Select from "react-select";

import * as config from "@config";
import http from "@http";
import logger from "@logger";

import Box from "../../Box";
import Modal from "./Modal";

export default function Instance() {
  // const { user }: any = useContext(AppContext)
  const [instances, setInstances]: any = useState(() => []);
  const [collection, setCollection]: any = useState({ value: "", label: "" });
  const [collections, setCollections]: any = useState([]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uniqueDbId = urlParams.get("uniqueDbId");
  logger.debug({ uniqueDbId }, "MongoBase.Instance");

  const [modalShow, setModalShow]: any = useState(() => false);
  // const handleShow = () => setModalShow(true)

  const getInstances = () => {
    if (collection.value) {
      http
        .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/database/collection/entries`, {
          pageNumber: 1,
          pageSize: 100,
          orderType: "DESC",
          collectionName: collection.value,
          uniqueInstanceId: uniqueDbId,
        })
        .then((response) => {
          logger.debug({ values: response.data.values }, "[Instance]");
          setInstances(() => response.data.rows);
        });
    }
  };

  useEffect(() => {
    http
      .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/database/collection`, {
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
        category: "regular",
        uniqueInstanceId: uniqueDbId,
      })
      .then((response) => {
        setCollections(() =>
          response.data.rows.map((row: any) => ({
            value: row.name,
            label: row.name,
          }))
        );
      });
  }, []);

  useEffect(() => {
    getInstances();
  }, [collection]);

  // logger.debug('Instance')
  return (
    <div>
      <br />

      <div className="row" style={{ margin: "0" }}>
        <div className="col-1" />
        <div className="col-4">
          <h4>Select Collection</h4>
          <Select className="form-control form-control-lg" value={collection} options={collections} defaultValue={collection} onChange={setCollection} />
        </div>
        <div className="col-3">
          <button onClick={() => setModalShow(() => true)} className="btn btn-success">
            Create Entry
          </button>
        </div>
      </div>

      <hr />
      <br />

      <div
        style={{
          // display: 'none',
          // display: 'flex',
          // justifyContent: 'space-between',
          // margin: '10px 24px',
          padding: "0",
        }}
        // className="row"
      >
        <Modal modalShow={modalShow} setModalShow={setModalShow} createBasePath={`/instance/${uniqueDbId}`} />

        <br />
        <ul>
          {instances
            ? instances.map((value: any, index: number) => {
                const allR = [];
                for (const [keyInner, valueInner] of Object.entries(value)) {
                  allR.push({
                    label: keyInner,
                    value: valueInner,
                  });
                }

                const rows = [
                  ...allR,
                  // {
                  //   label: 'id',
                  //   value: value.id,
                  // },
                  // {
                  //   label: 'Description',
                  //   value: value.description,
                  // },
                  // {
                  //   label: 'Content',
                  //   value: value.content,
                  //   hidden: false,
                  // },
                  // {
                  //   label: 'Active',
                  //   value: value.active,
                  // },
                ];

                return <Box key={index} rows={rows} clientId={value.clientId} apiBaseUrl={config.api.mongoBaseBaseUrl} patchBasePath={`/instance/${uniqueDbId}`} />;
              })
            : null}
        </ul>
      </div>
    </div>
  );
}
