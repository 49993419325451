import hooks from "@hooks";
import http from "@http";
import { FilterContext as FilterContext_I } from "@interfaces/helpers/Filter.interface";
import logger from "@log";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Elements from "./Elements";
import styles from "./Filter.module.scss";
import Pagination from "./Pagination";

export const FilterContext = React.createContext({});

function Index(props: FilterContext_I) {
  const [display, setDisplay]: any = useState(true);
  const [loading, setLoading] = hooks.useStateCallback(false);

  const fetchData = () => {
    http
      .makeGetRequestAuthorized(props.meta.mainUrl, { ...props.searchParams })
      .then((response: any) => {
        if (response && response.message === "success" && response.data) {
          props.setData && props.setData(response.data);
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    logger.debug({ searchParams: props.searchParams, loading }, "[Filter]");
    if (loading === false) {
      setLoading(() => true, fetchData());
    } else {
      // retry few times if it is still loading
      const count = 0;
      const retry = (count: number) => {
        logger.debug({ loading, count }, "[Filter][retry]");
        if (count < 4) {
          if (loading === false) {
            setLoading(() => true, fetchData());
          } else {
            setTimeout(() => retry(count + 1), 500);
          }
        }
      };
      retry(count);
    }
  }, [JSON.stringify(props.searchParams)]);

  //   }, [props.searchParams])
  //   logger.debug('Filter:index', styles)
  return (
    <FilterContext.Provider value={{ ...props }}>
      <div className={styles.Filter}>
        <div className={styles.Row}>
          <div className={styles.Left}>
            {display ? (
              <Button size="sm" variant="outline-info" onClick={() => setDisplay(false)}>
                Hide Filters
              </Button>
            ) : (
              <Button size="sm" variant="info" onClick={() => setDisplay(true)}>
                Show Filters
              </Button>
            )}
          </div>

          <div className={styles.Right}>{display && <Pagination />}</div>
        </div>
        <div className={styles.Row}>{display && <Elements />}</div>
      </div>
    </FilterContext.Provider>
  );
}

export default Index;
