import * as common from "./common";

const ENV: string = common.getEnv();
export const mode = ENV;

export const BASE_URL: string = common.getBaseUrl();
export const api = {
  baseUrl: common.getBaseApiUrl(),
  nodeBaseBaseUrl: `${common.getBaseApiUrl()}/nodebase`,
  mongoBaseBaseUrl: `${common.getBaseApiUrl()}/mongobase`,
  authPortBaseUrl: `${common.getBaseApiUrl()}/authport`,
  fileStoreBaseUrl: `${common.getBaseApiUrl()}/filestore`,
  dataStoreBaseUrl: `${common.getBaseApiUrl()}/datastore`,
  videoStoreBaseUrl: `${common.getBaseApiUrl()}/videostore`,
  videoStoreFileUrl: `${common.getVideoStoreViewUrl()}/video`,
  loggerBaseUrl: `${common.getBaseApiUrl()}/logger`,
  mailerBaseUrl: `${common.getBaseApiUrl()}/mailer`,
};

export const FILESTORE_VIEW_URL: string = common.getFileStoreViewUrl();
