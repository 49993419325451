import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ClientAttacher({ clientId }: any) {
  const [databaseNames, setDatabaseNames]: any = useState([]);
  const [databases, setDatabases]: any = useState([]);
  const [attachedDatabases, setAttachedDatabases]: any = useState([]);
  logger.debug({ clientId, databaseNames }, "ClientAttacher");

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setDatabaseNames(typeof value === "string" ? value.split(",") : value);
  };

  // 0) get
  const getAttachedDatabases = () => {
    http
      .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/manage/client/attach`, {
        clientId,
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
      })
      .then((response) => {
        setAttachedDatabases(response.data.rows);
      });
  };

  // 1) get
  const getAvailableDatabases = () => {
    http
      .makeGetRequestAuthorized(`${config.api.mongoBaseBaseUrl}/manage/client/available`, {
        clientId,
        pageNumber: 1,
        pageSize: 100,
        orderType: "DESC",
      })
      .then((response) => {
        setDatabases(response.data.rows);
      });
  };

  // 2) post
  const attachAvailableDatabases = () => {
    http
      .makePostRequestAuthorized(`${config.api.mongoBaseBaseUrl}/manage/client/attach/?clientId=${clientId}&pageNumber=${1}&pageSize=${100}&orderType=${"DESC"}`, {
        data: databaseNames.map((dn: any) => ({
          clientId,
          uniqueDbId: dn,
        })),
      })
      .then((response) => {
        if (response && response.message === "success") {
          // pass for now
        } else {
          error.messageHandling(response);
        }
      });
  };

  useEffect(() => {
    getAttachedDatabases();
    getAvailableDatabases();
  }, []);

  return (
    <div>
      <div>
        <h5>Attached Database(s)</h5>
        <ul>
          {attachedDatabases.map((ad: any) => (
            <li>{`${ad.dbName} (${ad.uniqueDbId})`}</li>
          ))}
        </ul>
      </div>
      <hr />
      <div>
        <h5>Select Database(s)</h5>
        <div className="row">
          <div className="col-3">
            {/* <Select
            className="form-control form-control-lg"
             value={subUser}
             options={subUserOptions}
             defaultValue={subUserOptions[0]}
             onChange={setSubUser}
          /> */}

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Database(s)</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={databaseNames}
                onChange={handleChange}
                input={<OutlinedInput label="Database(s)" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {databases.map((database: any, idx: number) => (
                  <MenuItem key={idx} value={database.uniqueDbId}>
                    <Checkbox checked={databaseNames.indexOf(database.uniqueDbId) > -1} />
                    <ListItemText primary={`${database.dbName} (${database.uniqueDbId})`} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6">
            <button onClick={() => attachAvailableDatabases()} className="btn btn-warning">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientAttacher;
