import { Link } from "react-router-dom";
import styles from "./Card.module.scss";
// import logger from '../../log'

function Index(props: { data: Array<{ value: any; label: string }>; to: string }) {
  return (
    <div className={`${styles.Card} row`}>
      <div className="col-md-11">
        {props.data &&
          props.data.map((componentData, idx) => {
            if (componentData.value) {
              return (
                <div key={idx}>
                  <b>{componentData.label}</b>:{componentData.value}
                </div>
              );
            }
            return null;
          })}
      </div>

      <div className="col-md-1">
        <Link to={props.to} className="btn btn-primary">
          View
        </Link>
      </div>
    </div>
  );
}

export default Index;
