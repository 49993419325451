import * as config from "@config";
import cookie from "@cookie";
import http from "@http";

const isAuthorized = () => {
  const jwtToken = cookie.readCookie("jwtToken");

  if (jwtToken) {
    return http
      .makeGetRequestHeaders(`${config.api.baseUrl}/auth/authorize`, {
        Authorization: jwtToken,
      })
      .then((response) => {
        if (response.message === "success") {
          return true;
        }
        return false;
      })
      .catch((err) => {
        console.error({ err }, "auth.module.catch");
        return false;
      });
  }
  return Promise.resolve(false);
};

export default {
  isAuthorized,
};
