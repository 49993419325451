const REMOTE_PRODUCTION_HOST = "cloud.codegk.com";
const LOCAL_PRODUCTION_HOST = "192.168.0.100:4235";
const PRODUCTION_HOSTS: string[] = [REMOTE_PRODUCTION_HOST, LOCAL_PRODUCTION_HOST];

export const HREF: string = window.location.href;
export const ORIGIN: string = window.location.origin;
export const HOST: string = window.location.host;
// console.log({ hostClean }, 'config.index')

export const getEnv = (): string => {
  let ENV: string;
  if (HREF.includes("https:") || PRODUCTION_HOSTS.includes(HOST)) {
    ENV = "production";
  } else {
    ENV = "development";
  }
  return ENV;
};

export const getBaseUrl = (): string => ORIGIN;
export const getBaseApiUrl = (): string => {
  const ENV: string = getEnv();
  if (ENV === "production") {
    switch (HOST) {
      case REMOTE_PRODUCTION_HOST:
        return "https://api-cloud.codegk.com";
      case LOCAL_PRODUCTION_HOST:
        return "http://192.168.0.100:8928";
      default:
        return "https://api-cloud.codegk.com";
    }
  } else {
    return "http://localhost:2259";
  }
};

export const getFileStoreViewUrl = (): string => {
  const ENV: string = getEnv();
  if (ENV === "production") {
    switch (HOST) {
      case REMOTE_PRODUCTION_HOST:
        return "https://api-filestore.codegk.com";
      case LOCAL_PRODUCTION_HOST:
        return "http://192.168.0.100:4079";
      default:
        return "https://api-filestore.codegk.com";
    }
  } else {
    return "https://api-filestore.codegk.com";
  }
};

export const getVideoStoreViewUrl = (): string => {
  const ENV: string = getEnv();
  if (ENV === "production") {
    switch (HOST) {
      case REMOTE_PRODUCTION_HOST:
        return "https://api-videostore.codegk.com";
      case LOCAL_PRODUCTION_HOST:
        return "http://192.168.0.100:4079";
      default:
        return "https://api-videostore.codegk.com";
    }
  } else {
    return "http://localhost:2249";
  }
};
