import createNotification from "@assets/nativelib/js/notification";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import * as config from "@config";
import error from "@error";
import http from "@http";
import logger from "@logger";

function Add() {
  const [data, setData]: any = useState({
    Name: "",
    Category: "",
    Type: "",
    Description: "",
  });

  const handleInputChange = (e: any) => {
    const { name } = e.target;
    const { value } = e.target;
    // logger.debug(name, value);
    setData({ ...data, [name]: value });
  };

  function validateForm() {
    return data.Name.length > 0;
  }

  // const resetInputs = () => {
  //   const resetDict = {}
  //   for (const [key, value] of Object.entries(data)) {
  //     resetDict[key] = ''
  //   }
  //   setData(() => {
  //     return resetDict
  //   })
  // }

  const handleInputSubmit = (event: any) => {
    event.preventDefault();
    return http
      .makePostRequestAuthorized(`${config.api.fileStoreBaseUrl}/manage/sub-user/`, {
        Name: data.Name,
        Category: data.Category,
        Type: data.Type,
        Description: data.Description,
      })
      .then((response) => {
        logger.debug({ response }, "FileStore.AddUser");
        if (response.message === "success") {
          // resetInputs();

          createNotification({
            title: "Succesful",
            message: "User Created",
            type: "success",
            container: "top-right",
          });

          // window.location.pathname = '/user/all/'
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        // logger.debug({ err }, 'FileStore.AddUser.catch')
        error.messageHandling(err);
      });
  };

  return (
    <div>
      <div className="Wrapper">
        <form onSubmit={handleInputSubmit}>
          <Form.Group>
            <Form.Label>
              Name <sup className="compulsory-field">*</sup>
            </Form.Label>
            <input className="form-control" name="Name" autoFocus type="text" value={data.Name} onChange={handleInputChange} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Category</Form.Label>
            <input className="form-control" name="Category" type="text" value={data.Category} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <input className="form-control" name="Type" type="text" value={data.Type} onChange={handleInputChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Short Description</Form.Label>
            <input className="form-control" name="Description" type="text" value={data.Description} onChange={handleInputChange} />
          </Form.Group>

          <Button type="submit" disabled={!validateForm()}>
            Submit
          </Button>
        </form>

        <br />
      </div>
    </div>
  );
}

export default Add;
