import React, { useEffect, useState } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { useLocation } from "react-router-dom";

// import CapturePathNameAndRedirectOnce from "@helpers/CapturePathNameAndRedirectOnce";
import { useRouter } from "@hooks";
import logger from "@log";
import sideEffects from "@sideEffects";
import auth from "@utils/auth";
import AppEntry from "./AppEntry";
import Public from "./Public";

export const AppContext = React.createContext({});
const TITLE = "Cloud | CodeGK";
// const TITLE_UPDATED = 'CodeGK'

export default function App() {
  const router = useRouter();
  const location = useLocation();
  // const navigation = useNavigate(); // previously on useHistory
  // const socket = io.connect(config.api.baseUrl)
  const socket = "";
  const [pathname, setPathname]: any = useState(window.location.pathname);
  const [user, setUser]: any = useState(auth.getDefaultUserObject());
  const [authorized, setAuthorized]: any = useState(false);
  const [pageLoadingDisplay, setPageLoadingDisplay]: any = useState(false);
  const [subTitle, setSubTitle]: any = useState("Home");
  const [title, setTitle]: any = useState(TITLE);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  useEffect(() => {
    document.title = title;
  }, [title]);

  // const setHalfTitle = (halfTitle) => {
  //   setTitle(() => halfTitle + ' | ' + TITLE_UPDATED)
  // }

  const setFullTitle = (fullTitle: string) => {
    setTitle(() => fullTitle);
  };

  const setSideEffects = (location: any) => {
    setPathname(location.pathname);
    sideEffects.favicon(location.pathname);
    setSubTitle(sideEffects.subTitle(location.pathname));
    setFullTitle(sideEffects.title(location.pathname));
  };

  useEffect(() => {
    logger.debug({ location, authorized }, "App.useEffect.navigation");
    setSideEffects(location);
  }, [location]);

  return (
    <AppContext.Provider
      value={{
        // 1) Attributes
        router,
        subTitle,
        queryString,
        urlParams,
        location,
        history,
        socket,
        user,
        authorized,
        pageLoadingDisplay,
        pathname,

        // 2) Methods
        setUser,
        setAuthorized,
        setPageLoadingDisplay,
      }}
    >
      <ReactNotifications />
      <Public />
      <AppEntry />
      {/* <CapturePathNameAndRedirectOnce pathname={pathname} /> */}
    </AppContext.Provider>
  );
}
