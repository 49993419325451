import { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import { usePrevious } from "../../hooks";
import styles from "./Filter.module.scss";
import { FilterContext } from "./index";
// import logger from '../../log'

// default data
const defaultData = {
  options: {
    pageSize: [
      { value: 4, label: "04" },
      { value: 6, label: "06" },
      { value: 10, label: "10" },
      { value: 20, label: "20" },
      { value: 250, label: "250" },
    ],
    orderType: [
      { value: "DESC", label: "Descending" },
      { value: "ASC", label: "Ascending" },
    ],
  },
  text: {
    inputSearch: "Search for Elements",
    pageSize: "Page Size",
    orderType: "Display Order",
  },
};

function Elements() {
  const { filterCustomData, paginationData, setPaginationData, filterData, setFilterData, ExtraFilter }: any = useContext(FilterContext);
  const { options, text } = filterCustomData;
  const [inputSearch, setInputSearch]: any = useState("");
  const inputSearchPrevious = usePrevious(inputSearch);
  const inputSearchRef: any = useRef(); // will use later for only search if inputSearch value changed

  const pageSizeOptions = options.pageSize || defaultData.options.pageSize;
  const [pageSize, setPageSize]: any = useState(pageSizeOptions[0]);

  const orderTypeOptions = options.orderType || defaultData.options.orderType;
  const [orderType, setOrderType]: any = useState(orderTypeOptions[0]);

  const handleInputSubmit = (event: any): void => {
    event.preventDefault();
    // logger.debug('handleInputSubmit', {
    //   inputSearch,
    //   inputSearchPrevious,
    // })
    setFilterData({
      ...filterData,
      inputSearch,
      orderType: orderType.value,
      lock: false,
    });
  };

  // TODO: little bit of work required here, since not working
  // const debounce = (func: Function, timeout = 1000) => {
  //   let timer: any;
  //   return (...args: any) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       const meThis: object = this;
  //       func.apply(meThis, args);
  //     }, timeout);
  //   };
  // };

  useEffect(() => {
    if (inputSearch !== inputSearchPrevious) {
      // do not instantly fetch if search value changed
      // although, saving data instantly locally to maintain integrity
      setFilterData({
        ...filterData,
        inputSearch,
        orderType: orderType.value,
        lock: true,
      });
      setPaginationData({ ...paginationData, pageSize: pageSize.value });

      setFilterData({
        ...filterData,
        inputSearch,
        orderType: orderType.value,
        lock: false,
      });
    } else {
      setFilterData({ ...filterData, inputSearch, orderType: orderType.value });
      setPaginationData({ ...paginationData, pageSize: pageSize.value });
    }
  }, [inputSearch, pageSize, orderType]);

  //   logger.debug('Filter.Element', ExtraFilter)
  return (
    <div className={styles.Elements}>
      <div className={styles.Left}>
        {ExtraFilter && (
          <div className={styles.ExtraFilter}>
            <ExtraFilter
              {...{
                filterCustomData,
                paginationData,
                setPaginationData,
                filterData,
                setFilterData,
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.Right}>
        <div className="col-md-4">
          <h6 className={styles.Label}>{text.inputSearch || defaultData.text.inputSearch}</h6>
          <Form onSubmit={handleInputSubmit}>
            <InputGroup size="sm" className="mb-3">
              <Form.Control size="sm" placeholder="Search" onChange={(event: any) => setInputSearch(event.target.value)} ref={inputSearchRef} />
              <div className="input-group-append">
                <Button variant="warning" size="sm" type="submit">
                  Submit
                </Button>
              </div>
            </InputGroup>
          </Form>
        </div>

        <div className="col-md-3">
          <h6 className={styles.Label}>{text.pageSize || defaultData.text.pageSize}</h6>
          <Select className="form-control form-control-sm" value={pageSize} options={pageSizeOptions} onChange={setPageSize} />
        </div>

        <div className="col-md-4">
          <h6 className={styles.Label}>{text.orderType || defaultData.text.orderType}</h6>
          <Select className="form-control form-control-sm" value={orderType} options={orderTypeOptions} onChange={setOrderType} />
        </div>
      </div>
    </div>
  );
}

export default Elements;
