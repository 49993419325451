import { AppContext } from "@App";
import React, { useContext } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";

import MongoBase from "./MongoBase";
import NodeBase from "./NodeBase";
import VoltBase from "./VoltBase";

import AuthPort from "./AuthPort";

import DataStore from "./DataStore";
import FileStore from "./FileStore";
import Surveillance from "./Surveillance";
import VideoStore from "./VideoStore";

import Logger from "./Logger";
import Mailer from "./Mailer";

import Account from "./Account";
import Dashboard from "./DashBoard";

import Loader from "./Loader";
import styles from "./User.module.scss";

function Body({ className, setLeftNavPath }: any) {
  const { pathname, subTitle, pageLoadingDisplay }: any = useContext(AppContext);
  return (
    <div className={`${className} ${styles.Body}`}>
      {subTitle && <h3 className={styles.subTitle}>{subTitle}</h3>}
      <Loader display={pageLoadingDisplay} />

      {/* Services */}
      {pathname.includes("nodebase") && <NodeBase path="/nodebase/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("mongobase") && <MongoBase path="/mongobase/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("voltbase") && <VoltBase path="/voltbase/" setLeftNavPath={setLeftNavPath} />}

      {pathname.includes("authport") && <AuthPort path="/authport/" setLeftNavPath={setLeftNavPath} />}

      {pathname.includes("filestore") && <FileStore path="/filestore/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("datastore") && <DataStore path="/datastore/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("videostore") && <VideoStore path="/videostore/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("surveillance") && <Surveillance path="/surveillance/" setLeftNavPath={setLeftNavPath} />}

      {pathname.includes("logger") && <Logger path="/logger/" setLeftNavPath={setLeftNavPath} />}
      {pathname.includes("mailer") && <Mailer path="/mailer/" setLeftNavPath={setLeftNavPath} />}

      {/* Account */}
      {pathname.includes("account") && <Account />}

      {/* Dashboard */}
      {pathname.includes("dashboard") && <Dashboard />}

      <Routes>
        {/* Home */}
        <Route
          path="/"
          element={
            <React.Fragment>
              Home <br />
              <Link to="/vs/embed">Vs Embed</Link>
            </React.Fragment>
          }
        />

        {/* Auth-Redirects */}
        <React.Fragment>
          <Route path="/login/" element={<Navigate to="/" />} />
          <Route path="/register/" element={<Navigate to="/" />} />
          <Route path="/forgot-password/" element={<Navigate to="/" />} />
        </React.Fragment>
      </Routes>
    </div>
  );
}

export default Body;
