import createNotification from "@assets/nativelib/js/notification";
import { useEffect, useState } from "react";

import * as config from "@config";
import error from "@error";
import http from "@http";

import "./Modify.css";

const Modify = ({ subUserId }: any) => {
  const [loading, setLoading]: any = useState(() => false);
  const [data, setData]: any = useState(() => {
    return {
      name: "",
      category: "",
      type: "",
      description: "",
      authToken: "",
      active: "",
    };
  });

  const [toggleModify, setToggleModify]: any = useState(() => false);

  const getInfo = () => {
    http
      .makeGetRequestAuthorized(`${config.api.fileStoreBaseUrl}/manage/sub-user/?subUserId=${subUserId}`, {})
      .then((response) => {
        // logger.debug({ re: response }, "");
        if (response.message === "success") {
          const rows = response.data.rows[0];
          if (response.data.count > 0) {
            setData(rows);
          } else {
            createNotification({
              title: "",
              message: "No Info Found",
              type: "success",
              container: "center",
            });
          }
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  const handleInputSubmit = (event: any) => {
    event.preventDefault();
    // logger.debug(data, "data");
    return http
      .makePatchRequestAuthorized(`${config.api.fileStoreBaseUrl}/manage/sub-user/?subUserId=${subUserId}`, {
        data: [
          {
            id: subUserId,
            name: data.name,
            category: data.category,
            description: data.description,
            auth_token: data.authToken,
            active: data.active,
          },
        ],
      })
      .then((response) => {
        // logger.debug({ rsp: response, re: response.errorMessage }, "");
        if (response.message && response.message === "success") {
          // resetInputs();

          createNotification({
            title: "Succesful",
            message: "User Modified",
            type: "success",
            container: "top-right",
          });

          const user = response.data.rows[0];
          setToggleModify(false);
          setData(user);
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  const handleInputChange = (event: any) => {
    setData(() => {
      return { ...data, [event.target.name]: event.target.value };
    });
  };

  useEffect(() => {
    if (loading === false) {
      setLoading(() => {
        return true;
      }, getInfo());
    }
  }, [loading]);

  const toggleActive = () => {
    if (data.active === "1") {
      setData({ ...data, active: "0" });
    } else {
      setData({ ...data, active: "1" });
    }
  };

  return (
    <div>
      <h3>Modify/View User</h3>
      <br />
      <br />

      {toggleModify == false ? (
        <table className="table table-striped table-hover table-file">
          <tr>
            <th>User Name</th>
            <td>{data.name}</td>
          </tr>
          <tr>
            <th>Category</th>
            <td>{data.category}</td>
          </tr>

          <tr>
            <th>Description</th>
            <td>{data.description}</td>
          </tr>
          <tr>
            <th>Auth Token</th>
            <td
              style={{
                color: "transparent",
                textShadow: "0 0 5px rgba(0,0,0,0.5)",
              }}
            >
              {"****************"}
              <span className="modify-users-copy-button">
                <button
                  className="btn btn-info"
                  onClick={() => {
                    navigator.clipboard.writeText(data.authToken);
                    createNotification({
                      title: "",
                      message: "Copied!",
                      type: "info",
                      duration: 220,
                    });
                  }}
                >
                  COPY
                </button>
              </span>
            </td>
          </tr>
          <tr>
            <th>Active</th>
            <td>{data.active === "1" ? "Yes" : "No"}</td>
          </tr>
          <br />
          <br />
          <tr>
            <button
              className="btn btn-outline-warning"
              onClick={() => {
                setToggleModify((prev: any) => {
                  !prev;
                });
              }}
            >
              Modify
            </button>
          </tr>
        </table>
      ) : (
        <table className="table table-striped table-hover table-file">
          <tr>
            <th>User Name</th>
            <td>
              <div className="placeholder-raiser-div width-100">
                <input name="name" value={data.name} onChange={handleInputChange} type="text" className="form-control placeholder-raiser-input placeholder-raiser-input-indigo" required />
              </div>
            </td>
          </tr>

          <tr>
            <th>Category</th>
            <td>
              <div className="placeholder-raiser-div width-100">
                <input name="category" value={data.category} onChange={handleInputChange} type="text" className="form-control placeholder-raiser-input placeholder-raiser-input-indigo" required />
              </div>
            </td>
          </tr>

          <tr>
            <th>Description</th>
            <td>
              <div className="placeholder-raiser-div width-100">
                <input
                  name="description"
                  value={data.description}
                  onChange={handleInputChange}
                  type="text"
                  className="form-control placeholder-raiser-input placeholder-raiser-input-indigo"
                  required
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>Auth Token</th>
            <td>
              <span className="modify-users-new-auth-token">
                <input
                  type="checkbox"
                  className="form-check-input"
                  onClick={(event: any) => {
                    if (event.target.checked) {
                      setData({
                        ...data,
                        authToken: "1",
                      });
                    } else {
                      setData({
                        ...data,
                        authToken: "0",
                      });
                    }
                  }}
                />
                Generate New
              </span>
            </td>
          </tr>
          <tr>
            <th>Active</th>
            <td>
              &nbsp;
              {data.active === "1" ? "Yes" : "No"}
              <span>&nbsp;&nbsp;</span>
              <button className="btn btn-sm btn-warning modify-users-active-toggle" onClick={toggleActive}>
                Toggle
              </button>
            </td>
          </tr>
          <br />
          <br />
          <tr style={{ display: "flex" }}>
            <button className="btn btn-outline-danger" onClick={() => setToggleModify(false)}>
              Discard
            </button>
            &nbsp;&nbsp;
            <button className="btn btn-primary" onClick={handleInputSubmit}>
              Submit
            </button>
          </tr>
          <br />
          <tr></tr>
        </table>
      )}

      <br />
      <br />
    </div>
  );
};

export default Modify;
