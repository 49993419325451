import { useEffect, useState } from "react";
import Select from "react-select";

import createNotification from "@assets/nativelib/js/notification";
import * as config from "@config";
import error from "@error";
import http from "@http";

import CheckBox from "./CheckBox";
import CheckBoxAll from "./CheckBoxAll";
import "./Rights.css";

const rightsOptions = [
  { value: "read", label: "read" },
  { value: "write", label: "write" },
  { value: "delete", label: "delete" },
];

function Rights({ data, dataRights, setDataRights, getInfo }: any) {
  const [updateData, setUpdateData]: any = useState([]);
  const [displayData, setDisplayData]: any = useState(dataRights);
  const [showModify, setShowModify]: any = useState(false); // default:false
  const [showAdd, setShowAdd]: any = useState(false); // default: false
  const [submitModifySpinner, setSubmitModifySpinner]: any = useState(false);
  const [right, setRight]: any = useState(rightsOptions[0]);
  const [addData, setAddData]: any = useState({
    subUserId: "", // new sub user id on input
    fileId: data.id,
    filePrivilegeType: "", // new sub user id on select
  });

  const handleInputSubmitModify = (event: any) => {
    event.preventDefault();
    setSubmitModifySpinner(true);

    const afterTimeout = () => {
      getInfo();
      setSubmitModifySpinner(false);
    };

    return http
      .makePatchRequestAuthorized(`${config.api.dataStoreBaseUrl}/manage/right/`, {
        data: updateData.map((value: any) => ({
          id: value.id,
          file_privilege_type: value.filePrivilegeType,
          file_beneficiary_id: value.fileBeneficiaryId ? value.fileBeneficiaryId : 0,
          active: value.active,
        })),
      })
      .then((response) => {
        if (response.message === "success") {
          createNotification({
            title: "Successful",
            message: "User Right Modified",
            type: "success",
          });
          setDataRights(() => [
            ...dataRights,
            {
              userId: addData.subUserId,
              userName: "pending...",
              filePrivilegeType: right.value, // todo: change to addData.filePrivilegeType soon
              active: "1",
            },
          ]);
          setTimeout(() => afterTimeout(), 1500); // default wait-time: 1500
        } else {
          afterTimeout();
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  const handleInputSubmitAdd = (event: any) => {
    event.preventDefault();
    return http
      .makePostRequestAuthorized(`${config.api.dataStoreBaseUrl}/manage/right/`, {
        dataId: data.id,
        subUserId: addData.subUserId,
        right: right.value, // todo: change to addData.filePrivilegeType soon
      })
      .then((response) => {
        if (response.message === "success") {
          createNotification({
            title: "Successful",
            message: "User Right Added",
            type: "success",
          });

          setDataRights(() => [
            ...dataRights,
            {
              userId: addData.subUserId,
              userName: "pending...",
              filePrivilegeType: right.value, // todo: change to addData.filePrivilegeType soon
              active: "1",
            },
          ]);

          setTimeout(() => getInfo(), 1500);
        } else {
          error.messageHandling(response);
        }
      })
      .catch((err) => {
        error.messageHandling(err);
      });
  };

  const handleInputChangeAdd = (event: any) => {
    setAddData({ ...addData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    let calculatedDisplayData = dataRights;
    calculatedDisplayData = dataRights.map((value: any) => {
      const indexUpdate = updateData.findIndex((object: any) => object.id === value.id);
      if (indexUpdate > -1) {
        return updateData[indexUpdate];
      }
      return value;
    });
    setDisplayData([...calculatedDisplayData]);
  }, [dataRights, updateData]);

  return (
    <div className="row">
      <div className="col-lg-9">
        <h2>File Right(s)</h2>
        <br />
        {showModify === false && showAdd === false ? (
          <button
            onClick={() => {
              setShowModify(!showModify);
            }}
            className="btn btn-outline-warning"
          >
            Modify
            {submitModifySpinner ? (
              <span>
                &nbsp;
                <span className="spinner-grow spinner-grow-sm" />
              </span>
            ) : null}
          </button>
        ) : null}
        {showModify && (
          <button
            style={{}}
            className="btn btn-danger"
            onClick={() => {
              setShowModify(false);
            }}
          >
            Discard
          </button>
        )}
        &nbsp;
        {showModify === true && showAdd === false && (
          <button
            onClick={(event: any) => {
              setShowModify(!showModify);

              handleInputSubmitModify(event);
            }}
            className="btn btn-primary"
          >
            Submit Modify
          </button>
        )}
        {showModify === false && showAdd === true ? (
          <button disabled className="btn btn-warning">
            Modify
          </button>
        ) : null}
        {showModify === true && showAdd === true ? (
          <button disabled className="btn btn-primary">
            Submit Modify
          </button>
        ) : null}
        &nbsp;
        {showAdd === false && showModify === false && (
          <button
            onClick={() => {
              setShowAdd(!showAdd);
            }}
            className="btn btn-warning"
          >
            Add
          </button>
        )}
        {showAdd === false && showModify === true ? (
          <button disabled className="btn btn-warning">
            Add
          </button>
        ) : null}
        &nbsp;
        {showAdd === true && showModify === false ? (
          <button
            onClick={(event: any) => {
              handleInputSubmitAdd(event);
            }}
            className="btn btn-primary"
          >
            Submit Add
          </button>
        ) : null}
        {showAdd === true && showModify === true ? (
          <button disabled className="btn btn-primary">
            Submit Add
          </button>
        ) : null}
        <br />
        <br />
        {showAdd && (
          <div className="rights__form__wrapper">
            <h4>Add User and Right for this File</h4>{" "}
            <button
              style={{ float: "right" }}
              className="btn btn-danger"
              onClick={() => {
                setShowAdd(false);
              }}
            >
              Discard
            </button>
            <form className="row">
              <div className="col-1" />
              <div className="placeholder-raiser-div width-100 col-5">
                <br />
                <span className="placeholder-raiser-label">
                  <span className="font-weight-bold">
                    Sub User Id <sup className="important-star">*</sup>
                  </span>
                </span>
                <input
                  name="subUserId"
                  value={addData.subUserId}
                  onChange={handleInputChangeAdd}
                  type="text"
                  className="form-control placeholder-raiser-input placeholder-raiser-input-indigo"
                  required
                />
              </div>
              <div className="placeholder-raiser-div width-100 col-5">
                <br />
                <span className="font-weight-bold">Right</span>
                <Select className="form-control form-control-lg" value={right} options={rightsOptions} defaultValue={rightsOptions[0]} onChange={setRight} />
              </div>
              <div className="col-1" />
            </form>
          </div>
        )}
        <br />
        <table className="table table-striped table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Sub User Id</th>
              <th>User Name</th>
              <th>Header Token</th>
              <th>Right</th>
              {!showModify && <th>Active</th>}
              {showModify && (
                <th className="toggle-file-right-head-box">
                  Toggle Active &nbsp;&nbsp;
                  <CheckBoxAll {...{ updateData, setUpdateData, displayData }} />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {displayData && displayData.length > 0
              ? displayData.map((value: any, index: number) => (
                  <tr key={index}>
                    {value.userId ? <td>{value.userId}</td> : <td>0</td>}
                    <td>{value.userName ? value.userName : "[PUBLIC]"}</td>
                    <td>
                      {value.headerToken ? (
                        <button
                          className="btn btn-outline-info copy-button"
                          onClick={() => {
                            navigator.clipboard.writeText(value.headerToken);
                            createNotification({
                              title: "Copied",
                              message: "",
                              type: "info",
                              duration: 420,
                            });
                          }}
                        >
                          COPY
                        </button>
                      ) : (
                        "pending..."
                      )}
                    </td>
                    <td>{value.dataPrivilegeType}</td>
                    {!showModify && (
                      <td style={{ background: "rgb(0,0,128,0.05)" }}>
                        {value.actives &&
                          (value.actives.split(",").every((active: string) => {
                            return active.includes("-1");
                          })
                            ? "1"
                            : "0")}
                      </td>
                    )}
                    {showModify && (
                      <td className="toggle-file-right-head-box" style={{ background: "rgb(0,0,128,0.05)" }}>
                        <CheckBox
                          {...{
                            value,
                            updateData,
                            setUpdateData,
                            displayData,
                            zero: value.active.toString() === "0",
                            one: value.active.toString() === "1",
                          }}
                          key={index}
                        />
                      </td>
                    )}
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
      <div className="col-lg-3" />
    </div>
  );
}

export default Rights;
